<div class="extended-categories-scroll advanced-search-container" style="overflow-x: hidden">
  <ng-container
    *ngFor="let advancedSetting of searchTagsService.settingsList; let advancedIdx = index"
  >
    <div
      class="col-xs-12 col-md-12 mt10 mb10 pl5 pr5"
      *ngIf="advancedSetting.category === 'advancedSettings'"
    >
      <ng-container *ngFor="let setting of advancedSetting.items; let settIdx = index">
        <ng-container
          *ngIf="!setting.allowed || (setting.allowed && commonService.isAllowed(setting.allowed))"
        >
          <div class="mt10 mb40" *ngIf="setting.type === 'date'" [id]="setting.slug">
            <p class="color-dark advanced-title mb0">
              {{ setting.label }}
              <i
                class="fa fa-info-circle"
                [tooltip]="setting.description"
                [placement]="'right'"
                [adaptivePosition]="false"
                [container]="'body'"
              ></i>
              <i
                class="fa fa-times"
                aria-hidden="true"
                *ngIf="setting.model"
                (click)="
                  setting.model = null; getDataByDate(setting, setting.model, advancedIdx, settIdx)
                "
              ></i>
            </p>
            <hr style="margin: 0; margin-bottom: 10px" />
            <div class="form-group">
              <input
                type="text"
                (keydown)="$event.stopPropagation()"
                placeholder="{{ setting.placeholder }}"
                class="form-control"
                placement="right"
                [(ngModel)]="setting.model"
                (ngModelChange)="getDataByDate(setting, setting.model, advancedIdx, settIdx)"
                bsDaterangepicker
                [bsConfig]="{
                  containerClass: 'theme-blue',
                  ranges: setting.rangeArray,
                  rangeInputFormat: 'll'
                }"
              />
            </div>
          </div>
          <div class="mt10 mb40 color-dark" *ngIf="setting.type === 'options'" [id]="setting.slug">
            <p class="advanced-title mb0">
              {{ setting.label }}
              <i
                class="fa fa-info-circle"
                [tooltip]="setting.description"
                [placement]="'right'"
                [adaptivePosition]="false"
                [container]="'body'"
              ></i>
            </p>
            <hr style="margin: 0; margin-bottom: 10px" />
            <div *ngFor="let option of setting.options; let optionIdx = index">
              <ng-container *ngIf="option.slug !== 'country'">
                <div
                  *ngIf="optionIdx === 1 && isAdmin"
                  (click)="setting.collapse = !setting.collapse"
                  class="mt15"
                  style="cursor: pointer"
                >
                  <p>
                    Options
                    <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="!setting.collapse"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="setting.collapse"></i>
                  </p>
                </div>
                <div *ngIf="!setting.collapse" class="pl15">
                  <label class="mt10 mb0">{{ option.label }}</label>
                  <div *ngFor="let type of option.types">
                    <input
                      type="checkbox"
                      class="color-dark"
                      (change)="
                        changeRecordingLocation(setting, type, option, advancedIdx, settIdx)
                      "
                      [(ngModel)]="type.value"
                    />
                    <p class="color-dark ml5 display-i">{{ type.label }}</p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="option.slug === 'country'" class="mb5">
                <!-- <label class="mt10 mb0">{{option.label}}</label> -->
                <p class="mt5">
                  Country
                  <button
                    *ngIf="
                      option.country &&
                      setting?.value?.includes(option.country) &&
                      !currentUrl.includes('/artists-list')
                    "
                    class="btn xs-btn outline-primary-btn"
                    [ngClass]="{ 'primary-btn': option.negative }"
                    (click)="
                      changeLocation(
                        setting,
                        option,
                        'country',
                        advancedIdx,
                        settIdx,
                        option.negative ? false : true
                      )
                    "
                  >
                    Exclude
                  </button>
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                    *ngIf="option.country"
                    (click)="
                      option.country = '';
                      changeLocation(setting, option, 'country', advancedIdx, settIdx)
                    "
                  ></i>
                </p>
                <input
                  data-hj-allow
                  type="text"
                  (keydown)="$event.stopPropagation()"
                  (keyup)="
                    !option.country &&
                      changeLocation(setting, option, 'country', advancedIdx, settIdx)
                  "
                  class="form-control mt10"
                  [typeahead]="countriesDropdown"
                  (click)="requestValidation('countries')"
                  [(ngModel)]="option.country"
                  (typeaheadOnSelect)="
                    option.city = '';
                    option.state = '';
                    changeLocation(setting, option, 'country', advancedIdx, settIdx)
                  "
                  [typeaheadOptionField]="'name'"
                  [typeaheadOptionsLimit]="5"
                  [typeaheadMinLength]="0"
                  placeholder="Select a country"
                />
                <div
                  class="mt10"
                  *ngIf="option.country === 'United States of America'"
                  id="locationState"
                >
                  <p>
                    State
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      *ngIf="option.state"
                      (click)="
                        option.state = '';
                        changeLocation(setting, option, 'state', advancedIdx, settIdx)
                      "
                    ></i>
                  </p>
                  <input
                    data-hj-allow
                    class="form-control"
                    type="text"
                    (keydown)="$event.stopPropagation()"
                    (keyup)="
                      !option.state &&
                        changeLocation(setting, option, 'state', advancedIdx, settIdx)
                    "
                    [typeahead]="statesDropdown"
                    [(ngModel)]="option.state"
                    (typeaheadOnSelect)="
                      option.city = '';
                      changeLocation(setting, option, 'state', advancedIdx, settIdx)
                    "
                    [typeaheadOptionField]="'name'"
                    [typeaheadOptionsLimit]="5"
                    [typeaheadMinLength]="0"
                    placeholder="Select a state"
                  />
                </div>
                <div *ngIf="option.showCityInput" class="mb10 mt10">
                  <p>
                    City
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      *ngIf="option.city"
                      (click)="
                        option.city = '';
                        changeLocation(setting, option, 'city', advancedIdx, settIdx)
                      "
                    ></i>
                  </p>
                  <div style="position: relative">
                    <input
                      class="form-control pr20"
                      type="text"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="option.city"
                      (change)="changeLocation(setting, option, 'city', advancedIdx, settIdx)"
                      placeholder="Type a city"
                    />
                    <span
                      class="material-icons-outlined input-icon"
                      [attr.data-icon]="'keyboard_return'"
                      (click)="changeLocation(setting, option, 'city', advancedIdx, settIdx)"
                    >
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div
            class="mt10 mb40 color-dark"
            *ngIf="setting.type === 'multiselect'"
            [id]="setting.slug"
          >
            <p class="advanced-title mb0 advanced-title">
              {{ setting.label }}
              <i
                class="fa fa-info-circle"
                [tooltip]="setting.description"
                [placement]="'right'"
                [adaptivePosition]="false"
                [container]="'body'"
              ></i>
            </p>
            <hr style="margin: 0; margin-bottom: 10px" />
            <input
              data-hj-allow
              type="text"
              [container]="'body'"
              (click)="
                typeaheadHideResultsOnBlur = !typeaheadHideResultsOnBlur;
                requestValidation(setting.slug)
              "
              (keydown)="$event.stopPropagation()"
              class="form-control mt10 mb10"
              [typeahead]="multiSelect[setting.slug]?.data"
              [(ngModel)]="setting.model"
              (typeaheadOnSelect)="selectItemMultiSelect(setting, advancedIdx, settIdx)"
              [typeaheadOptionField]="'name'"
              [typeaheadOptionsLimit]="5"
              [typeaheadMinLength]="0"
              [placeholder]="setting.placeholder"
              [dropup]="true"
            />
            <button
              class="btn sm-btn outline-primary-btn"
              *ngFor="let elm of setting.resultArray"
              (click)="deselectItemMultiSelect(setting, elm, advancedIdx, settIdx)"
            >
              {{ elm }}
            </button>
          </div>
          <div class="mt10 mb40 color-dark" *ngIf="setting.type === 'text'" [id]="setting.slug">
            <p class="advanced-title mb0 advanced-title">
              {{ setting.label }}
              <i
                class="fa fa-info-circle"
                [tooltip]="setting.description"
                [placement]="'right'"
                [adaptivePosition]="false"
                [container]="'body'"
              ></i>
              <i
                class="fa fa-times"
                aria-hidden="true"
                *ngIf="setting.value"
                (click)="setting.value = ''; changeTextOptions(setting, advancedIdx, settIdx)"
              ></i>
            </p>
            <hr style="margin: 0; margin-bottom: 10px" />
            <div style="position: relative">
              <input
                class="form-control pr20"
                type="text"
                (keydown)="$event.stopPropagation()"
                [(ngModel)]="setting.value"
                (change)="changeTextOptions(setting, advancedIdx, settIdx)"
                [placeholder]="setting.placeholder"
              />
              <span
                class="material-icons-outlined input-icon"
                [attr.data-icon]="'keyboard_return'"
                (click)="changeTextOptions(setting, advancedIdx, settIdx)"
              >
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="isAdmin" class="col-xs-12 col-md-12 mt0 pl5 pr5" id="embeddedVersions">
    <div class="mb40">
      <p class="color-dark advanced-title mb0">Recommender Versions</p>
      <hr style="margin: 0; margin-bottom: 10px" />
      <div *ngFor="let version of embeddedVersions" class="pl10">
        <input
          type="checkbox"
          class="color-dark"
          (change)="changeEmbedding(version)"
          [(ngModel)]="version.value"
        />
        <label class="color-dark ml5">{{ version.name }}</label>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullPageLoaderComponent implements OnInit {
  ngOnInit() {}
}

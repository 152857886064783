import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { clone } from 'lodash-es';
import { searchBarSection } from '../../modules/shared-layout/configs/search-bar-section.config';
import { CommonService } from './common.service';
import { PlayerService } from './player.service';
import { SearchTagsService } from './search-tags.service';

@Injectable({
  providedIn: 'root',
})
export class ManageUrlTagsService {
  public settingsList = clone(searchBarSection().settingsList);
  public tagsBySlug: any = {};
  public advancedTags: any = {};
  public advancedSearchSettingsDefault = searchBarSection().advancedSearchSettings;
  public budgetSettings = searchBarSection().budgetSettings;
  public notTagGenericCategories = [
    'artist',
    'stats',
    'bpm',
    'advanced-search',
    'restrictions',
    'artists',
    'type',
    'explicit',
    'query',
    'tiers',
    'budget',
    'covers',
    'hasRhythm',
    'oneStop',
    'ref-song',
    'youtube-ref',
    'playlist',
  ];

  constructor(
    private commonService: CommonService,
    private searchTagsService: SearchTagsService,
    private currency: CurrencyPipe,
    private playerService: PlayerService,
  ) {}

  setTagsByUrl(params: Params, currentUrl?: string) {
    const {
      tags,
      vocals,
      explicit,
      artists,
      restrictions,
      q,
      qFields,
      tierId,
      isOneStop,
      isCover,
      hasRhythm,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      seed_songIds,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      seed_youtube,
    } = params;
    this.addVocalTags(vocals);
    this.addListenersTag(params['min.followers'], params['max.followers'], 'min.followers');
    this.addListenersTag(
      params['min.monthlyListeners'],
      params['max.monthlyListeners'],
      'min.monthlyListeners',
    );
    this.addListenersTag(
      params['minInstagram.followers'],
      params['maxInstagram.followers'],
      'minInstagram.followers',
    );
    this.addListenersTag(
      params['minSpotify.followers'],
      params['maxSpotify.followers'],
      'minSpotify.followers',
    );
    this.addListenersTag(
      params['minSpotify.monthlyListeners'],
      params['maxSpotify.monthlyListeners'],
      'minSpotify.monthlyListeners',
    );
    this.addListenersTag(
      params['minDeezer.followers'],
      params['maxDeezer.followers'],
      'minDeezer.followers',
    );
    this.addListenersTag(
      params['minDeezer.monthlyListeners'],
      params['maxDeezer.monthlyListeners'],
      'minDeezer.monthlyListeners',
    );
    this.addListenersTag(
      params['minAppleMusic.followers'],
      params['maxAppleMusic.followers'],
      'minAppleMusic.followers',
    );
    this.addListenersTag(
      params['minAppleMusic.monthlyListeners'],
      params['maxAppleMusic.monthlyListeners'],
      'minAppleMusic.monthlyListeners',
    );
    this.addBpmTag(params['min.bpm'], params['max.bpm']);
    this.addGenericTag(tags);
    this.createExplicitTag(explicit);
    this.addArtistTag(currentUrl, artists);
    this.addAdvanceTags(params);
    this.addRestrictionsTag(restrictions);
    this.addQueryTag(q, qFields);
    this.addTierTag(tierId);
    this.addBudgetTags(params);
    this.createOneStopTag(isOneStop);
    this.createCoverTag(isCover);
    this.createRhythmTag(hasRhythm);
    this.createSimilarSongTag(params, seed_songIds, 'refSong');
    this.createSimilarSongTag(params, seed_youtube, 'youtube-ref');
    this.addPlaylistTag(currentUrl);
  }

  initObjectTagsBySlug(tags: any[], params: Params, currentUrl?: string) {
    this.tagsBySlug = {
      full: { category: 'type', label: 'Full/Vocal', slug: 'full' },
      instrumental: { category: 'type', label: 'Instrumental', slug: 'instrumental' },
      explicit: { category: 'explicit', label: 'Exclude explicit', slug: 'explicit' },
    };
    this.addTagsBySlug(tags);
    this.createAdvanceTags();
    this.setTagsByUrl(params, currentUrl);
  }

  public addTagsBySlug(tags: any[]) {
    tags.forEach((tag) => {
      this.tagsBySlug[tag.slug] = tag;
      this.addTagsBySlug(tag.tags || tag.items || []);
    });
  }

  addVocalTags(vocals: string) {
    if (vocals) {
      const currentTag = this.tagsBySlug[vocals === 'true' ? 'full' : 'instrumental'];
      if (currentTag) {
        this.searchTagsService.addTag(currentTag);
      }
    } else {
      this.searchTagsService.selectedTags.forEach((tag: any) => {
        if (tag.category === 'type') {
          this.searchTagsService.removeTag(tag);
        }
      });
    }
  }

  createStatsRangeTag(minValue: number, maxValue: number, option?: any) {
    const statsSource = option || this.advancedSearchSettingsDefault.statsSource[0];
    let maxStatsValue: any = maxValue;
    if (maxStatsValue === 10000000) {
      maxStatsValue = 'Unlimited';
    } else {
      maxStatsValue = this.commonService.addLetterToLargeNumber(maxStatsValue);
    }
    return {
      category: 'stats',
      label: `${statsSource.itemName}:
        ${this.commonService.addLetterToLargeNumber(minValue)}
         - ${maxStatsValue}`,
      slug: `stats-range-${statsSource.id}`,
      type: 'stats-range',
      minQueryProp: statsSource.minQueryProp,
      maxQueryProp: statsSource.maxQueryProp,
      minValue: minValue,
      maxValue: maxValue,
    };
  }

  addListenersTag(minListeners: string, maxListeners: string, minQueryProp: string) {
    if (minListeners) {
      const statSource =
        this.advancedSearchSettingsDefault.statsOptions.find(
          (element) => element.minQueryProp === minQueryProp,
        ) || this.advancedSearchSettingsDefault.statsSource[0];
      const max = parseInt(maxListeners || '10000000');
      const min = parseInt(minListeners);
      const tag = this.createStatsRangeTag(min, max, statSource);
      this.searchTagsService.addStatsTag(tag);
    }
  }

  createBpmRangeTag(minValue: number, maxValue: number) {
    let maxStatsValue: any = maxValue;
    if (maxStatsValue === 200) {
      maxStatsValue = 'Unlimited';
    } else {
      maxStatsValue = this.commonService.addLetterToLargeNumber(maxStatsValue);
    }
    return {
      category: 'bpm',
      label: `BPM: ${minValue} - ${maxStatsValue}`,
      slug: `bpm-range`,
      type: 'bpmRange',
      minValue: minValue,
      maxValue: maxStatsValue,
    };
  }

  addBpmTag(minBpm: string, maxBpm: string) {
    if (minBpm) {
      const max = parseInt(maxBpm || '200');
      const min = parseInt(minBpm);
      const tag = this.createBpmRangeTag(min, max);
      this.searchTagsService.addBpmRangeTag(tag);
    }
  }

  addGenericTag(tags: string) {
    const tagsToSearch = tags?.split(',') || [];
    const tagsObject = tagsToSearch.map((tag) => ({
      slug: tag.includes('!') ? tag.split('!')[1]?.trim() : tag,
      negative: tag.includes('!'),
    }));

    const slugTags = tagsObject.map((tagO) => tagO.slug);
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (!slugTags.includes(tag.slug) && !this.notTagGenericCategories.includes(tag.category)) {
        this.searchTagsService.removeTag(tag);
      }
    });

    const currentTags = tagsObject.map((tagO) => this.tagsBySlug[tagO.slug]);
    currentTags.forEach((tag, index) => {
      if (tag) {
        const isNegative = tagsObject[index].negative;
        tag.selectedNegative = isNegative;
        tag.selected = !isNegative;
        tag.negative = isNegative;
        this.searchTagsService.addTag(tag);
      }
    });

    this.getGenericTags(tags);
  }

  getGenericTags(tags) {
    let genericTags = tags?.split(',');
    if (genericTags !== undefined) {
      const idx = genericTags?.indexOf('playlist');
      if (idx !== -1) {
        genericTags.splice(idx, 1);
      }
      genericTags = genericTags.join();
    }
    this.searchTagsService.genericTagUpdated.next(genericTags);
  }

  createArtistTag(artistSlug: string, artistName: string, artistId?: string) {
    return {
      category: 'artists',
      hover: true,
      id: artistId || artistSlug,
      label: artistName,
      negative: undefined,
      slug: artistSlug,
    };
  }

  addArtistTag(currentUrl: any, artistParams: string) {
    const artistSlug = artistParams
      ? artistParams
      : currentUrl?.includes('/artist/')
      ? currentUrl.split('/artist/')[1].split('?')[0]
      : null;

    let artists = [];
    if (artistSlug) {
      artists = artistSlug.split(',');
      artists.forEach((element) => {
        const tag = this.createArtistTag(element, element);
        this.searchTagsService.addTag(tag);
      });
    }
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (tag.category == 'artists' && !artists.includes(tag.slug)) {
        this.searchTagsService.removeTag(tag);
      }
    });
  }

  createAdvanceTags() {
    const advancedSettings = this.settingsList.find((item) => item.category === 'advancedSettings');
    advancedSettings.items.forEach((item, index) => {
      if (!item.options) {
        this.advancedTags[item.slug] = {
          category: 'advanced-search',
          label: item.label,
          slug: item.slug,
          idx1: 0,
          idx2: index,
        };
        return;
      }
      if (item.slug === 'artistLocation') {
        this.advancedTags['artistLocation.city'] = {
          category: 'advanced-search',
          label: `${item.label}`,
          slug: `${item.slug}.country`,
          idx1: 0,
          idx2: index,
        };
      }
      item.options?.forEach((option) => {
        this.advancedTags[`${item.slug}.${option.slug}`] = {
          category: 'advanced-search',
          label: item.label,
          slug: `${item.slug}.${option.slug}`,
          idx1: 0,
          idx2: index,
        };
      });
    });

    this.addEmbedding();
  }

  addEmbedding() {
    this.advancedTags.embeddingsVersion = {
      category: 'advanced-search',
      label: 'Recommender Version',
      slug: 'embeddingsVersion',
    };
  }

  addAdvanceTags(params: Params) {
    const keysParams = Object.keys(params);
    const slugOldTags = this.searchTagsService.selectedTags.filter(
      (tag: any) => tag.category === 'advanced-search' && !keysParams.includes(tag.slug),
    );
    slugOldTags.forEach((tag) => {
      this.searchTagsService.removeTag(tag);
    });

    Object.keys(params).forEach((param) => {
      const tag = this.advancedTags[param];
      if (tag) {
        this.searchTagsService.addTag(tag);
      }
    });
  }

  addRestrictionsTag(tags: string) {
    const tagsToSearch = tags?.split(',') || [];
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (!tagsToSearch.includes(tag.slug) && tag.category === 'restrictions') {
        this.searchTagsService.removeTag(tag);
      }
    });
    const currentTags = tagsToSearch.map((tagO) => this.tagsBySlug[tagO]);
    currentTags.forEach((tag) => {
      if (tag) {
        const isNegative = false;
        tag.selectedNegative = isNegative;
        tag.selected = !isNegative;
        tag.negative = isNegative;
        this.searchTagsService.addTag(tag);
      }
    });
  }

  addQueryTag(query: string, field: string) {
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if ((!query || `query-${field}-${query}` !== tag.slug) && tag.category === 'query') {
        this.searchTagsService.removeTag(tag);
      }
    });

    if (query && field) {
      const newTag = {
        category: 'query',
        label: `${field}: ${query}`,
        slug: `query-${field}-${query}`,
        type: field,
        value: query,
      };
      this.searchTagsService.addTag(newTag);
    }
  }

  addTierTag(tierId: string) {
    const tagsUrl = tierId?.split(',') || [];

    const tagsObject = tagsUrl.map((tag) => ({
      slug: tag.includes('!') ? tag.split('!')[1]?.trim() : tag,
      negative: tag.includes('!'),
    }));

    const slugTags = tagsObject.map((tagO) => tagO.slug);

    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (!slugTags.includes(tag.slug) && tag.category === 'tiers') {
        this.searchTagsService.removeTag(tag);
      }
    });
    slugTags
      .map((tag) => this.tagsBySlug[tag])
      .forEach((tag, index) => {
        if (tag) {
          const isNegative = tagsObject[index].negative;
          tag.selectedNegative = isNegative;
          tag.selected = !isNegative;
          tag.negative = isNegative;
          this.searchTagsService.addTag(tag);
        }
      });
  }

  addBudgetTags(params: Params) {
    const budgetParams = Object.keys(params).filter((param) => param.includes('maxBudget.'));
    const slugOldTags = this.searchTagsService.selectedTags.filter((tag: any) => {
      if (tag.category === 'budget') {
        if (budgetParams.length === 0) {
          return true;
        }
        return !(
          budgetParams.includes(`maxBudget.${tag.slug}`) ||
          tag.slug === `budget-amount-${params[budgetParams[0]]}`
        );
      }
      return false;
    });
    slugOldTags.forEach((tag) => {
      this.searchTagsService.removeTag(tag);
    });

    budgetParams.forEach((budgetParam) => {
      const amount = params[budgetParam];
      const tagAmount = this.getBudgetAmountTag(parseFloat(amount));
      this.searchTagsService.addTag(tagAmount);
      const budgetIds = budgetParam.split('.');
      if (budgetIds.length > 1) {
        const dropDownOption = this.budgetSettings.dropDownOptions.find(
          (item) => item.id === budgetIds[1],
        );
        const optionSelected = dropDownOption?.options.find(
          (option) => option.id === `${budgetIds[1]}${budgetIds[2] ? '.' + budgetIds[2] : ''}`,
        );
        if (dropDownOption && optionSelected) {
          const budgetTag = {
            category: 'budget',
            label: `${dropDownOption.label} (${optionSelected.itemName})`,
            slug: optionSelected.id,
            type: 'budgetOption',
          };
          this.searchTagsService.addTag(budgetTag);
        }
      }
    });
  }

  public getBudgetAmountTag(amount: number) {
    return {
      category: 'budget',
      label:
        amount < 50000
          ? `Budget ${this.currency.transform(amount, 'USD', 'symbol', '0.0')}`
          : 'Any Budget',
      slug: `budget-amount-${amount}`,
      type: 'budgetAmount',
      value: amount,
    };
  }

  private createOneStopTag(isOneStop: string) {
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (tag.category === 'oneStop' && (!isOneStop || tag.negative.toString() !== isOneStop)) {
        this.searchTagsService.removeTag(tag);
      }
    });

    if (isOneStop) {
      const isNegative = isOneStop == 'true' ? false : true;
      const tag = {
        category: 'oneStop',
        label: 'One Stop',
        slug: 'isOneStop',
        selectedNegative: !!isNegative,
        selected: !!!isNegative,
        negative: isNegative,
      };
      this.searchTagsService.addTag(tag);
    }
  }

  private createCoverTag(isCover: string) {
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (tag.category === 'covers' && (!isCover || tag.negative.toString() !== isCover)) {
        this.searchTagsService.removeTag(tag);
      }
    });

    if (isCover) {
      const isNegative = isCover == 'true' ? false : true;
      const tag = {
        category: 'covers',
        label: 'Covers',
        slug: 'isCovers',
        selectedNegative: !!isNegative,
        selected: !!!isNegative,
        negative: isNegative,
      };
      this.searchTagsService.addTag(tag);
    }
  }

  private createRhythmTag(isRhythm: string) {
    this.searchTagsService.selectedTags.forEach((tag: any) => {
      if (tag.category === 'hasRhythm' && (!isRhythm || tag.negative.toString() !== isRhythm)) {
        this.searchTagsService.removeTag(tag);
      }
    });

    if (isRhythm) {
      const isNegative = isRhythm == 'true' ? false : true;
      const tag = {
        category: 'hasRhythm',
        label: 'Rhythm',
        slug: 'hasRhythm',
        selectedNegative: !!isNegative,
        selected: !!!isNegative,
        negative: isNegative,
      };
      this.searchTagsService.addTag(tag);
    }
  }

  private createExplicitTag(explicitParam: string) {
    if (explicitParam) {
      this.searchTagsService.addTag(this.tagsBySlug.explicit);
    } else {
      this.searchTagsService.removeTag(this.tagsBySlug.explicit);
    }
  }

  private createSimilarSongTag(params: Params, paramSimilar: string, type: string) {
    if (!paramSimilar) {
      const oldReSongTag = this.searchTagsService.selectedTags.findIndex(
        (t: any) => t.type === type,
      );
      if (oldReSongTag !== -1) {
        this.searchTagsService.selectedTags.splice(oldReSongTag, 1);
      }
      return;
    }
    const tag: any = {
      category: type === 'youtube-ref' ? 'youtube-ref' : 'ref-song',
      label: type === 'youtube-ref' ? 'Ext. Reference' : 'Similar Songs',
      slug: type === 'youtube-ref' ? params.seed_youtube : params.seed_songIds,
      selected: true,
      type: 'refSong',
    };
    if (params.seed_start) {
      tag.seed_start = params.seed_start;
    }
    if (params.seed_end) {
      tag.seed_end = params.seed_end;
    }

    if (params.seed_groups) {
      tag.seed_groups = params.seed_groups;
    }

    const songReference = JSON.parse(localStorage.getItem('SOSTEREO.similarSongRef') || '{}');
    if (!songReference.versions || songReference.versions.match?._id !== tag.slug) {
      if (type === 'youtube-ref') {
        this.setYoutubeRef(tag);
      } else {
        this.setLocalStorageSongRef(tag.slug);
        this.searchTagsService.refSongTag(tag);
      }
    } else {
      this.searchTagsService.refSongTag(tag);
    }
  }

  private setLocalStorageSongRef(songId) {
    const songData = {
      versions: {
        instrumental: {},
        vocals: {},
        match: { id: songId, _id: songId, trackId: songId },
        others: [],
      },
    };
    songData.versions.vocals = songData.versions.match;
    localStorage.setItem('SOSTEREO.similarSongRef', JSON.stringify(songData));
  }

  private setYoutubeRef(tag) {
    this.searchTagsService.removeTag(tag.slug);
    this.searchTagsService.sendSearch();
    this.playerService.showFullScreenSearch = true;
    setTimeout(() => {
      const url: any =
        tag.slug?.length > 25
          ? `Ext://${tag.slug}`
          : tag.slug?.length <= 15
          ? `https://www.youtube.com/watch?v=${tag.slug}`
          : `https://open.spotify.com/track/${tag.slug}`;
      this.playerService.searchChangeValue.next(url);
    });
  }

  createPlaylistTag(playlistSlug: string) {
    return {
      category: 'playlist',
      hover: true,
      id: playlistSlug,
      label: 'Playlist',
      negative: undefined,
      slug: 'playlist',
    };
  }

  addPlaylistTag(currentUrl: any) {
    // This is to add the tag 'playlist' when user is in route '/playlist' or '/edit-playlist'
    // const plIdentifier = currentUrl?.includes('/playlist/')
    //   ? currentUrl.split('/playlist/')[1].split('?')[0]
    //   : currentUrl?.includes('/edit-playlist')
    //   ? 'playlistLibrary'
    //     : null;
    const plIdentifier = currentUrl?.includes('/edit-playlist') ? 'playlistLibrary' : null;
    if (plIdentifier) {
      const tag = this.createPlaylistTag(plIdentifier);
      this.searchTagsService.addTag(tag);
    } else {
      this.searchTagsService.selectedTags.forEach((tag: any) => {
        if (tag.category === 'playlist') {
          this.searchTagsService.removeTag(tag);
        }
      });
    }
  }
}

<div *ngIf="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()">
  <span
    class="ss-icon-search sm-search-icon"
    [ngStyle]="{ left: company && !company?.theme?.sidebarMenu[0] && '0' }"
    *ngIf="playerService.isSmallSize"
    (click)="
      searchOpen = true;
      showBlocker = true;
      (deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) && goToSearch()
    "
  ></span>
  <span
    class="tags-selected-quantity"
    [ngStyle]="{ left: company && !company?.theme?.sidebarMenu[0] && '12vw' }"
    *ngIf="searchTagsService.selectedTags.length > 0 && showTagQuantity"
    >+{{ searchTagsService.selectedTags.length }}
  </span>
</div>
<div
  class="search-sidebar-bg"
  (click)="showBlocker = false; closeTab()"
  *ngIf="playerService.isSmallSize && showBlocker"
></div>
<div id="search-sidebar">
  <div
    id="collapsed-main-search-sidebar"
    *ngIf="collapsedSearchSidebar"
    (click)="openSearchSection(currentUrl.includes('/artists-list'))"
  >
    <span
      class="material-icons-outlined collapsed-search-icon center-flex"
      [attr.data-icon]="'search'"
    >
    </span>
  </div>
  <div
    id="main-search-sidebar"
    *ngIf="
      !collapsedSearchSidebar &&
      (playerService.showSearchSidebar || ratingQueueView) &&
      (!playerService.isSmallSize || (playerService.isSmallSize && searchOpen))
    "
    [ngClass]="{ 'sm-search-sidebar': playerService.isSmallSize }"
  >
    <div class="row ml0 mr0">
      <div class="search-filters-menu col-sm-12 pl0 pr0" style="height: 100vh">
        <div
          *ngIf="!ratingQueueView"
          class="pt10"
          [ngClass]="{ mt30: playerService.isSmallSize, mt5: !playerService.isSmallSize }"
        >
          <h6 (click)="toggleFullScreenSearch(true)" class="filters-title">
            <img
              *ngIf="!searchTagsService.useOriginalCatalog && subdomain && tenantId === 'sostereo'"
              [src]="logoSrc"
              style="width: 30px; height: auto"
              onerror="this.src='./assets/images/logos/stereo-brands.png'"
            />
            <span
              class="ss-icon-search"
              *ngIf="
                isBrowser &&
                (!subdomain || searchTagsService.useOriginalCatalog || tenantId !== 'sostereo')
              "
            ></span>
            <span class="blinking-cursor">|</span>
            Search
          </h6>
        </div>
        <div
          *ngIf="
            !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) &&
            currentUrl.includes('/edit-playlist') &&
            !playerService.showExtendedSearchSidebar.getValue()
          "
          class="close-pl-section center-flex"
          (click)="hideSearchSection(currentUrl.includes('/artists-list'))"
        >
          <span class="material-icons-outlined" [attr.data-icon]="'chevron_left'"> </span>
        </div>
        <hr *ngIf="searchTagsService.useOriginalCatalog" class="title-underline mt0 mb5" />
        <div class="selected-tag-container pt5 pl5">
          <ng-container
            *ngFor="let selectedTag of searchTagsService.selectedTags; trackBy: trackByIndex"
          >
            <div
              class="selected-tag"
              [ngClass]="{ 'negative-tag': selectedTag.negative, mt10: playerService.isSmallSize }"
              [ngStyle]="{ 'margin-right': playerService.isSmallSize ? '10px' : '3px' }"
              (click)="toggleTagType(selectedTag); !ratingQueueView ? sendSearch() : ''"
              (mouseenter)="selectedTag.selectedHover = true"
              (mouseleave)="selectedTag.selectedHover = false"
            >
              <span [ngClass]="{ 'pl5 pr5': playerService.isSmallSize }">
                {{ selectedTag.label }}
              </span>
              <div
                *ngIf="selectedTag.selectedHover || playerService.isSmallSize"
                class="remove-btn"
                (click)="$event.stopPropagation(); removeTag(selectedTag)"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </ng-container>
          <div
            class="selected-tag"
            *ngIf="currentUrl.includes('lyrics=open')"
            (mouseenter)="lyricsTag.hover = true"
            (mouseleave)="lyricsTag.hover = false"
          >
            <span>
              {{ lyricsTag.label }}
            </span>
            <div
              *ngIf="lyricsTag.hover"
              class="remove-btn"
              (click)="$event.stopPropagation(); removeLyricsOption()"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
          <div *ngIf="playerService.isProcessingReference === 'processing'" class="selected-tag">
            <span
              >Ref: External {{ playerService.percentageReference.getValue() }}%
              <i class="spin medium"></i>
            </span>
          </div>
        </div>
        <div
          class="tabs-container"
          [ngStyle]="{
            'padding-bottom':
              deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
                ? '130px'
                : '60px'
          }"
        >
          <button
            *ngIf="!ratingQueueView && !currentUrl.includes('/artists-list')"
            (click)="navigate()"
            type="button"
            [ngClass]="{ 'white-btn-active-category': currentUrl.includes('discover') }"
            class="btn tag-btn white-btn overflow-text text-left"
          >
            Discover
            <i
              class="fa fa-times ml5"
              *ngIf="currentUrl.includes('discover') && searchTagsService.selectedTags.length > 0"
            ></i>
          </button>
          <button
            *ngIf="
              !plLibrary &&
              !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) &&
              !currentUrl.includes('/artists-list')
            "
            (click)="changePlaylistView()"
            type="button"
            class="btn tag-btn white-btn overflow-text text-left hidden-for-mobile playlist-btn"
            [ngClass]="{ 'white-btn-active-category': showPlaylistMenu }"
          >
            Playlists
            <span
              *ngIf="editorialPlaylistTotal > 0 && !showPlaylistMenu"
              class="badge badge-info"
              [@inOutAnimation]
            >
              {{ editorialPlaylistTotal < 100 ? editorialPlaylistTotal : '+99' }}
            </span>
          </button>
          <ng-container *ngFor="let tab of tabs; let tabIndex = index">
            <div
              [tooltip]="
                tab.slug === 'budget' &&
                company?.customAgreement &&
                (subdomainCheck || !searchTagsService.useOriginalCatalog)
                  ? tab.tooltip
                  : null
              "
              [placement]="'right'"
              [adaptivePosition]="false"
              *ngIf="validateTab(tab)"
            >
              <button
                [tooltip]="
                  (tab.slug === 'catalogs' || tab.slug === 'brands') &&
                  searchTagsService.useOriginalCatalog
                    ? tab.tooltip
                    : null
                "
                [placement]="'right'"
                [adaptivePosition]="false"
                (click)="changeTab(tab)"
                [ngClass]="{
                  'white-btn-active-category':
                    tab.active &&
                    !displayLyrics &&
                    playerService.showExtendedSearchSidebar.getValue(),
                  pr5: tab.slug === 'catalogs'
                }"
                type="button"
                [disabled]="
                  tab.slug === 'budget' &&
                  company?.customAgreement &&
                  (subdomainCheck || !searchTagsService.useOriginalCatalog)
                "
                class="btn tag-btn white-btn overflow-text text-left"
              >
                {{ tab.name }}
                <i
                  *ngIf="
                    (tab.slug === 'settings' && occuracyCheck) ||
                    ((tab.slug === 'catalogs' || tab.slug === 'brands') &&
                      searchTagsService.useOriginalCatalog)
                  "
                  class="fa fa-exclamation pull-right"
                  [tooltip]="tab.slug === 'settings' && occuracyCheck ? tab.tooltip : null"
                  [placement]="'right'"
                  [adaptivePosition]="false"
                ></i>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !(deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) ||
      ((deviceDetectorService.isMobile() || deviceDetectorService.isTablet()) && searchOpen)
    "
    class="transform-elm initial-width extended-search-sidebar"
    [ngClass]="{
      'full-width': playerService.showExtendedSearchSidebar.getValue(),
      'sm-extended-search-sidebar': playerService.isSmallSize,
      'lyrics-open-pl-view':
        currentUrl.includes('/edit-playlist') &&
        collapsedSearchSidebar &&
        (displayLyrics || suggestionSongService.showPlaylistSettings.getValue()) &&
        playerService.showExtendedSearchSidebar.getValue()
    }"
  >
    <div *ngIf="playerService.showExtendedSearchSidebar.getValue()" class="fade-in-animation">
      <div class="row pl0 pr0 ml0 mr0">
        <ng-container
          *ngIf="
            ((activeTab &&
              (activeTab.slug === 'genres' ||
                activeTab.slug === 'moods' ||
                activeTab.slug === 'vocal' ||
                activeTab.slug === 'rhythm' ||
                activeTab.slug === 'instrumentation' ||
                activeTab.slug === 'bpm' ||
                activeTab.slug === 'harmony') &&
              !activeTab.preTags) ||
              expandedTag) &&
            !displayLyrics
          "
        >
          <!-- The a tag gets repeated so the tooltip changes dynamically -->
          <a
            *ngIf="sortType === 'label'"
            (click)="changeSortType()"
            style="right: 40px"
            [tooltip]="'Sort by total songs'"
            class="expanded-section-close pull-right read-desc mb"
          >
            <i aria-hidden="true" class="fa fa-sort-amount-asc mr10"></i>
          </a>
          <a
            *ngIf="sortType !== 'label'"
            (click)="changeSortType()"
            style="right: 40px"
            [tooltip]="'Sort alphabetically'"
            class="expanded-section-close pull-right read-desc mb"
          >
            <i aria-hidden="true" class="fa fa-sort-amount-asc mr10"></i>
          </a>
        </ng-container>
        <a (click)="closeTab()" class="expanded-section-close pull-right read-desc mb">
          <i aria-hidden="true" class="fa fa-times mr10"></i>
        </a>
        <ng-container *ngIf="displayLyrics">
          <div style="padding-top: 41px">
            <ng-container *ngIf="playerService.lyric">
              <p class="pl20 color-dark fw500">LYRICS</p>
              <div
                [innerHtml]="playerService.lyric | lineBreak"
                style="overflow: auto; overflow-x: hidden"
                class="lyrics-container pl20 pt20 pr10 scroll-bar"
              ></div>
            </ng-container>
            <div *ngIf="!playerService.lyric" class="lyrics-info">
              <p>
                We have very granular selection of songs with lyrics info, if you want to search by
                certain word that you want it to be part of the lyrics click on the button bellow
              </p>
              <button class="btn primary-btn sm-btn" (click)="toggleFullScreenSearch(true)">
                Search by lyrics
              </button>
            </div>
          </div>
        </ng-container>
        <div
          *ngIf="!displayLyrics"
          class="col-sm-12 pl0 pr0"
          [ngStyle]="{ 'padding-top': expandedTag ? '39px' : '41px' }"
        >
          <div
            class="back-to-lyrics"
            *ngIf="currentUrl.includes('lyrics=open') || currentUrl.includes('qFields=lyric')"
          >
            <a (click)="displayLyrics = true">< Back to lyrics</a>
          </div>
          <ng-container *ngIf="activeTab?.slug === 'catalogs'">
            <h1 class="sidenav-text-style ml10 mr10">Custom Catalog & Terms</h1>
            <hr class="m0" />
            <h3 class="sidenav-text-style mr20 ml10 mt10 mb20">
              Select the catalog and terms you wish to search
            </h3>
            <button
              *ngIf="!commonService.isAllowed(['CreateSearchAggrementRestricted'])"
              (click)="changeCatalog(true, true)"
              type="button"
              (mouseenter)="sostereoCheck = true"
              (mouseleave)="sostereoCheck = false"
              class="btn tag-btn white-btn overflow-text text-left"
            >
              <div class="center-flex" style="width: 100%">
                <div class="tag-label" [ngClass]="{ 'pretag-width': activeTab.preTags }">
                  <span>SoStereo</span>
                  <span class="display-i-block" *ngIf="company?.theme?.displayTierCopy"
                    >(Standard Tier)</span
                  >
                </div>
                <div
                  class="selection-tag-opt"
                  *ngIf="sostereoCheck || searchTagsService.useOriginalCatalog"
                  [ngClass]="{ 'check-selected-tag-opt': searchTagsService.useOriginalCatalog }"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="more-categories-opt"></div>
              </div>
            </button>
            <button
              (click)="changeCatalog(false, true)"
              (mouseenter)="subdomainCheck = true"
              (mouseleave)="subdomainCheck = false"
              type="button"
              class="btn tag-btn white-btn overflow-text text-left"
            >
              <div class="center-flex" style="width: 100%">
                <div class="tag-label" [ngClass]="{ 'pretag-width': activeTab.preTags }">
                  <span>{{ subdomain | titlecase }}</span>
                  <span class="display-i-block" *ngIf="company?.theme?.displayTierCopy"
                    >(Lower Tier)</span
                  >
                </div>
                <div
                  class="selection-tag-opt"
                  *ngIf="subdomainCheck || !searchTagsService.useOriginalCatalog"
                  [ngClass]="{ 'check-selected-tag-opt': !searchTagsService.useOriginalCatalog }"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="more-categories-opt"></div>
              </div>
            </button>
            <div class="terms-container" *ngIf="subdomain">
              <h3 *ngIf="agreement" class="sidenav-text-style">
                Click bellow to view {{ subdomain | titlecase }} custom terms
              </h3>
              <h3 *ngIf="!agreement" class="sidenav-text-style">
                Login to view {{ subdomain | titlecase }} custom terms
              </h3>
              <button
                [disabled]="!agreement"
                class="btn primary-btn sm-btn"
                (click)="getAgreement()"
              >
                View terms
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'song-type'">
            <app-song-type-search
              [htmlTags]="htmlTags"
              (sendSearchEvent)="sendSearch()"
              (updateTags)="updateTags($event)"
            ></app-song-type-search>
          </ng-container>
          <ng-container
            *ngIf="
              activeTab &&
              [
                'genres',
                'moods',
                'instrumentation',
                'restrictions',
                'vocal',
                'rhythm',
                'bpm',
                'harmony',
                'tiers',
                'references'
              ].includes(activeTab.slug) &&
              !expandedTag
            "
          >
            <div>
              <ng-container *ngIf="activeTab.slug === 'restrictions'">
                <h1 class="sidenav-text-style ml10 mr10">
                  Is your brand:
                  <i
                    class="fa fa-info-circle"
                    [tooltip]="
                      'Some artists have certain restrictions.' +
                      ' By telling us which category your search is in, we can show artists that are clear of any restrictions'
                    "
                    [placement]="'right'"
                  ></i>
                </h1>
                <hr class="m0" />
              </ng-container>
              <div
                class="extended-categories-scroll"
                [ngClass]="{ pb80: activeTab.slug === 'restrictions' }"
              >
                <div *ngIf="activeTab.description" class="mb20">
                  <h6 class="pl10 pr10 color-dark mt0" style="height: auto">
                    {{ activeTab.description.title }}
                  </h6>
                  <p class="pl10 pr10 color-dark" style="font-size: 12px">
                    {{ activeTab.description.text }}
                  </p>
                  <hr style="margin: 0" />
                </div>
                <ng-container
                  *ngFor="let tag of activeTab.tags; let tagIndex = index; trackBy: trackByIndex"
                >
                  <div
                    style="position: relative"
                    (mouseenter)="tag.infoIcon = tag.disable && !activeTab.preTags"
                    (mouseleave)="tag.infoIcon = false"
                  >
                    <button
                      (click)="
                        activeTab.preTags
                          ? getSlugOpenFullScreen(tag.slug)
                            ? toggleFullScreenSearch(
                                true,
                                getSlugOpenFullScreen(tag.slug)?.placeholder
                              )
                            : expandTag(tag)
                          : toggleTagSelection(tag, false)
                      "
                      (mouseenter)="tag.hover = true"
                      (mouseleave)="tag.hover = false"
                      type="button"
                      [ngClass]="{ disabled: tag.disable && !activeTab.preTags }"
                      class="btn tag-btn white-btn overflow-text text-left"
                    >
                      <div class="center-flex" style="width: 100%">
                        <div class="tag-label" [ngClass]="{ 'pretag-width': activeTab.preTags }">
                          <span>{{ tag.updatedLabel || tag.label }}</span>
                        </div>
                        <div
                          class="selection-tag-opt"
                          *ngIf="!activeTab.preTags"
                          [ngClass]="{
                            'check-selected-tag-opt': tag.selected,
                            'exclude-selected-tag-opt': !tag.hover && tag.selectedNegative
                          }"
                        >
                          <i
                            *ngIf="
                              !getSlugOpenFullScreen(tag.slug) &&
                              (playerService.isSmallSize ||
                                tag.hover ||
                                tag.selected ||
                                tag.selectedNegative)
                            "
                            class="fa fa-check"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          class="selection-tag-opt"
                          *ngIf="!activeTab.preTags && !isArtistList"
                          (click)="$event.stopPropagation(); toggleTagSelection(tag, true)"
                          [ngClass]="{
                            'check-selected-tag-opt': !tag.hover && tag.selected,
                            'exclude-selected-tag-opt': tag.selectedNegative
                          }"
                        >
                          <i
                            *ngIf="
                              !getSlugOpenFullScreen(tag.slug) &&
                              activeTab.slug !== 'restrictions' &&
                              !isArtistList &&
                              (playerService.isSmallSize ||
                                tag.hover ||
                                tag.selectedNegative ||
                                tag.selected)
                            "
                            class="fa fa-ban"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="more-categories-opt">
                          <span
                            class="pull-right"
                            *ngIf="
                              (playerService.isSmallSize || tag.hover) &&
                              getSlugOpenFullScreen(tag.slug)
                            "
                            (click)="
                              $event.stopPropagation();
                              toggleFullScreenSearch(
                                true,
                                getSlugOpenFullScreen(tag.slug)?.placeholder
                              );
                              tag.hover = false
                            "
                          >
                            <i class="fa fa-external-link" aria-hidden="true"></i>
                          </span>
                          <span
                            *ngIf="
                              (playerService.isSmallSize || tag.hover) &&
                              !getSlugOpenFullScreen(tag.slug) &&
                              tag.category !== 'mood' &&
                              tag.items &&
                              tag.items.length > 0
                            "
                            class="pull-right"
                            (click)="$event.stopPropagation(); expandTag(tag)"
                          >
                            <b> ></b>
                          </span>
                        </div>
                      </div>
                    </button>
                    <i
                      *ngIf="tag.disable && !activeTab.preTags && tag.infoIcon"
                      class="fa fa-info-circle info-icon-position"
                      aria-hidden="true"
                      [tooltip]="
                        'This tag is de-activated because there would be 0 results with your search criteria if included'
                      "
                    ></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="activeTab.slug === 'bpm' && commonService.isAllowed(['*'])">
                  <div class="col-xs-12 col-md-12 mt10 mb10 pl5 pr5">
                    <div style="height: 300px">
                      <div
                        *ngIf="isBrowser"
                        class="custom-slider mt10 mb10"
                        style="height: 100%"
                        (click)="enableBpmSlider()"
                        [tooltip]="'Click to enable the slider'"
                        [isDisabled]="!bpmSliderSettings.disabled"
                      >
                        <ngx-slider
                          [(value)]="advancedSearchSettings.minBpm.value"
                          [(highValue)]="advancedSearchSettings.maxBpm.value"
                          [options]="bpmSliderSettings"
                          (userChangeEnd)="onBpmSliderChange()"
                        ></ngx-slider>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="expandedTag">
            <button
              (click)="$event.stopPropagation(); goBackToParentTag()"
              type="button"
              class="btn tag-btn white-btn overflow-text text-left"
            >
              <div class="center-flex">
                <div class="more-categories-opt">
                  <span>
                    <b> < </b>
                  </span>
                </div>
                <span class="pl10 fw600" style="margin-right: auto">
                  {{ expandedTag.label }} ({{ expandedTag.total }})
                </span>
              </div>
            </button>
            <hr style="margin: 0" />
            <i
              class="spin medium loader-margin"
              *ngIf="expandedLoader"
              style="display: table; margin: 0 auto; margin-top: 15px"
            ></i>
            <ng-container *ngIf="!expandedLoader">
              <div class="extended-categories-scroll" style="height: 85vh">
                <button
                  *ngIf="activeTab?.slug === 'genres'"
                  (click)="toggleTagSelection(expandedTag, false)"
                  (mouseenter)="expandedTag.hover = true"
                  (mouseleave)="expandedTag.hover = false"
                  type="button"
                  [ngClass]="{ disabled: expandedTag.disable }"
                  class="btn tag-btn white-btn overflow-text text-left"
                >
                  <div class="center-flex">
                    <div class="tag-label">
                      <span>All</span>
                    </div>
                    <div
                      class="selection-tag-opt"
                      [ngClass]="{
                        'check-selected-tag-opt': expandedTag.selected,
                        'exclude-selected-tag-opt':
                          !expandedTag.hover && expandedTag.selectedNegative
                      }"
                    >
                      <i *ngIf="isExpandAndSmallSize()" class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div
                      class="selection-tag-opt"
                      *ngIf="!isArtistList"
                      (click)="$event.stopPropagation(); toggleTagSelection(expandedTag, true)"
                      [ngClass]="{
                        'check-selected-tag-opt': !expandedTag.hover && expandedTag.selected,
                        'exclude-selected-tag-opt': expandedTag.selectedNegative
                      }"
                    >
                      <i *ngIf="isExpandAndSmallSize()" class="fa fa-ban" aria-hidden="true"></i>
                    </div>
                    <div class="more-categories-opt"></div>
                  </div>
                </button>
                <ng-container *ngFor="let tag of expandedTag.tags; let expandedTagIndex = index">
                  <div
                    style="position: relative"
                    (mouseenter)="tag.infoIcon = tag.disable"
                    (mouseleave)="tag.infoIcon = false"
                  >
                    <button
                      (click)="toggleTagSelection(tag, false)"
                      (mouseenter)="tag.hover = true"
                      (mouseleave)="tag.hover = false"
                      type="button"
                      [ngClass]="{ disabled: tag.disable }"
                      class="btn tag-btn white-btn overflow-text text-left"
                    >
                      <div class="center-flex">
                        <div class="tag-label">
                          <span>{{ tag.updatedLabel || tag.label }}</span>
                        </div>
                        <div
                          class="selection-tag-opt"
                          [ngClass]="{
                            'check-selected-tag-opt': tag.selected,
                            'exclude-selected-tag-opt': !tag.hover && tag.selectedNegative
                          }"
                        >
                          <i
                            *ngIf="
                              playerService.isSmallSize ||
                              tag.hover ||
                              tag.selected ||
                              tag.selectedNegative
                            "
                            class="fa fa-check"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <!-- <div
                          class="selection-tag-opt"
                          (click)="$event.stopPropagation(); toggleTagSelection(tag, true)"
                          [ngClass]="{
                            'check-selected-tag-opt': !tag.hover && tag.selected,
                            'exclude-selected-tag-opt': tag.selectedNegative
                          }"
                        >
                          <i
                            *ngIf="
                              playerService.isSmallSize ||
                              tag.hover ||
                              tag.selectedNegative ||
                              tag.selected
                            "
                            class="fa fa-ban"
                            aria-hidden="true"
                          ></i>
                        </div> -->
                        <div class="more-categories-opt">
                          <span
                            *ngIf="(playerService.isSmallSize || tag.hover) && tag.items"
                            class="pull-right"
                            (click)="$event.stopPropagation(); expandTag(tag)"
                          >
                            <b> ></b>
                          </span>
                        </div>
                      </div>
                    </button>
                    <i
                      *ngIf="tag.disable && tag.infoIcon"
                      class="fa fa-info-circle info-icon-position"
                      aria-hidden="true"
                      [tooltip]="
                        'This tag is de-activated because there would be 0 results with your search criteria if included'
                      "
                    ></i>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'budget'">
            <h1 class="sidenav-text-style ml10 mr10">Budget</h1>
            <hr class="m0" />
            <div class="row mt10 ml0 mr0 extended-categories-scroll" style="height: 75vh">
              <h3 class="sidenav-text-style ml10 mr10">1. Select your project media</h3>
              <div
                *ngFor="let dropDownOption of budgetSettings.dropDownOptions"
                class="col-xs-12 pl5 pr5"
                align="center"
              >
                <div class="multiselect-wrapper mt5 mb5">
                  <angular2-multiselect
                    [data]="dropDownOption.options"
                    [settings]="{
                      text: dropDownOption.dropdownSettings.text | uppercase,
                      enableSearchFilter: false,
                      enableCheckAll: false,
                      badgeShowLimit: 1,
                      disabled: dropDownOption.dropdownSettings.disabled
                    }"
                    [(ngModel)]="dropDownOption.model"
                    (onSelect)="onBudgetOptionSelect(dropDownOption, $event)"
                    (onDeSelect)="onBudgetOptionDeSelect(dropDownOption, $event)"
                  >
                    <c-badge>
                      <ng-template let-item="item">
                        <label class="custom-label">
                          {{ dropDownOption.dropdownSettings.text | uppercase }}
                        </label>
                      </ng-template>
                    </c-badge>
                  </angular2-multiselect>
                </div>
              </div>
              <div class="col-xs-12 col-md-12 mt20 mb10 budget-slider" style="height: 300px">
                <h3 class="sidenav-text-style">2. Adjust the price range</h3>
                <p class="color-dark">
                  Range
                  <i
                    style="margin-left: 5px; font-size: 13px"
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    [tooltip]="'Search is based on 6 month term'"
                    [placement]="'top'"
                  ></i>
                </p>
                <div
                  *ngIf="isBrowser"
                  class="custom-slider mb10"
                  style="height: 100%"
                  [tooltip]="budgetSliderSettings.disabled ? 'Select an option above first' : ''"
                  [placement]="'top'"
                >
                  <ngx-slider
                    class="budget-slider"
                    [(ngModel)]="budgetSettings.amount"
                    [options]="budgetSliderSettings"
                    (userChangeEnd)="onBudgetAmountChange()"
                  ></ngx-slider>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'followers'">
            <div class="listeners-container col-xs-12 col-md-12 mt10 mb10 pl5 pr5">
              <p class="color-dark">Listeners & Followers</p>
              <div class="multiselect-wrapper mt10">
                <angular2-multiselect
                  [data]="advancedSearchSettings.statsOptions"
                  [settings]="{
                    text: 'Select Source',
                    enableSearchFilter: false,
                    enableCheckAll: false,
                    singleSelection: true
                  }"
                  [(ngModel)]="advancedSearchSettings.statsSource"
                  (onSelect)="onStatsOptionSelect()"
                  (onDeSelect)="onStatsOptionDeselect($event)"
                >
                </angular2-multiselect>
              </div>
              <div style="height: 300px">
                <div
                  *ngIf="isBrowser"
                  class="custom-slider mt10 mb10"
                  style="height: 100%"
                  (click)="enableStatsSlider()"
                  [tooltip]="'Click to enable the slider'"
                  [isDisabled]="!statsSliderSettings.disabled"
                >
                  <ngx-slider
                    [(value)]="advancedSearchSettings.minStats.value"
                    [(highValue)]="advancedSearchSettings.maxStats.value"
                    [options]="statsSliderSettings"
                    (userChangeEnd)="onStatsSliderChange()"
                  ></ngx-slider>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'brands'">
            <h1 class="sidenav-text-style ml10 mr10">Brands</h1>
            <hr class="m0" />
            <div class="extended-categories-scroll pb80">
              <button
                *ngFor="let agreementCompany of agreements"
                (click)="
                  agreementCompany.check = !agreementCompany.check;
                  changeCatalog(false, false, agreementCompany.check ? agreementCompany : null)
                "
                (mouseenter)="agreementCompany.hoverCheck = true"
                (mouseleave)="agreementCompany.hoverCheck = false"
                type="button"
                class="btn tag-btn white-btn overflow-text text-left"
              >
                <div class="center-flex" style="width: 100%">
                  <div class="tag-label" [ngClass]="{ 'pretag-width': activeTab.preTags }">
                    <span>{{ agreementCompany.name | titlecase }}</span>
                  </div>
                  <div
                    class="selection-tag-opt"
                    *ngIf="agreementCompany.hoverCheck || agreementCompany.check"
                    [ngClass]="{ 'check-selected-tag-opt': !searchTagsService.useOriginalCatalog }"
                    [ngClass]="{ 'check-selected-tag-opt': agreementCompany.check }"
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <div class="more-categories-opt"></div>
                </div>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'settings'">
            <app-settings-sidebar
              [settingList]="searchTagsService.settingsList"
              [isSearching]="true"
              (sendSearchEvent)="sendSearch()"
            ></app-settings-sidebar>
          </ng-container>
          <ng-container *ngIf="suggestionSongService.showPlaylistSettings.getValue()">
            <app-settings-sidebar
              [settingList]="suggestionSongService.settingList"
              (sendSearchEvent)="suggestionSongService.setChanges()"
            ></app-settings-sidebar>
          </ng-container>
          <ng-container *ngIf="activeTab?.slug === 'advanced-search'">
            <app-advanced-search
              [isAdmin]="isAdmin"
              [currentUrl]="currentUrl"
              (sendSearch)="sendSearch()"
              (removeTag)="removeTag($event)"
            ></app-advanced-search>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="full-screen-search" *ngIf="playerService.showFullScreenSearch" [@inOutAnimation]>
    <app-full-screen-search
      [searchPlaceholder]="searchPlaceholder"
      [uploadFile]="uploadFile"
      [subdomain]="subdomain"
      [currentUrl]="currentUrl"
      [loggedUser]="loggedUser"
      [uploadToken]="uploadToken"
      [isMobileOrTablet]="isMobileOrTablet"
      (toggleFullScreenSearchEmit)="toggleFullScreenSearch($event)"
      (toggleTagTypeEmit)="toggleTagType($event)"
      (sendSearchEmit)="sendSearch()"
      (removeTagEmit)="removeTag($event)"
      (removeLyricsOptionEmit)="removeLyricsOption()"
      (updateActiveTabEmit)="updateActiveTab()"
    ></app-full-screen-search>
  </div>
  <div
    *ngIf="showPlaylistMenu"
    class="pl0 pr0 playlist-menu-container"
    [ngClass]="{ 'collapsed-playlist-menu': collapsedSearchSidebar }"
  >
    <app-playlist-menu
      #playlistMenuComponent
      [draggedRow]="draggedRow"
      [selectedPlayDetail]="playlistId"
      [selectedPlaylistId]="playlistId"
      [editorialSearchParam]="playerService.editorialSearchParam"
      [searchView]="true"
      [goToEditorial]="playerService.goToEditorial"
      (editPlaylist)="editPlaylist($event)"
      (playlistSelected)="playlistSelected($event)"
      (deleteEditorialParam)="playerService.editorialSearchParam = $event"
    >
    </app-playlist-menu>
  </div>
</div>
<app-terms-modal #termsModalComponent></app-terms-modal>
<app-edit-playlist-modal #editPlaylistModal></app-edit-playlist-modal>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { ToastrService } from 'ngx-toastr';
import { SearchTagsService } from 'src/app/angular/shared/services/search-tags.service';
import { PlayerService } from 'src/app/angular/shared/services/player.service';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { clone, debounce, findIndex } from 'lodash-es';
import {
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  takeUntil,
} from 'rxjs';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { SearchService } from 'sostereo-services';
import { SearchConfigService } from 'src/app/angular/shared/services/search-config.service';
import { Router } from '@angular/router';
import { suggestionsExamples } from '../config-elements';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation, outInAnimation } from 'src/app/angular/shared/animations/in-out.animation';

@Component({
  selector: 'app-full-screen-search',
  templateUrl: './full-screen-search.component.html',
  styleUrl: './full-screen-search.component.scss',
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenSearchComponent implements OnDestroy {
  @ViewChild('captchaRef', { static: false }) recaptcha: RecaptchaComponent;
  @Output() toggleFullScreenSearchEmit = new EventEmitter(); //
  @Output() toggleTagTypeEmit = new EventEmitter(); //
  @Output() sendSearchEmit = new EventEmitter(); //
  @Output() removeTagEmit = new EventEmitter(); //
  @Output() removeLyricsOptionEmit = new EventEmitter(); //
  @Output() updateActiveTabEmit = new EventEmitter(); //
  @Input() uploadFile: boolean; //
  @Input() subdomain: string; //
  @Input() currentUrl: string; //
  @Input() loggedUser: boolean; //
  @Input() uploadToken: string; //
  @Input() isMobileOrTablet: boolean; //
  @Input() removeEditorialParam: boolean;
  @Input() searchPlaceholder: string; //

  private searchTypingTimeout: any = null;
  public showSettings = false;
  private playlistObserver$: Subject<any> = new Subject();
  public heightLimits = this.searchConfigService.getHeightLimits();
  public suggestionsExamples = suggestionsExamples;
  public tagsHeights = this.searchConfigService.getTagsHeights();
  public tenantId = this.commonService.tenantId;
  public atLeastOnePositiveTag = false;
  public noResults = false;
  public typedSearch = '';
  private searchChangeValue$: Subscription;
  public suggestionsLoading = false;
  public suggestions: any = null;
  public isProcessingReference = 'none';
  public percentageReference = '0';
  public externalAudio = '';
  private destroyed$ = new Subject<void>();
  public wavesReady: boolean = false;
  public segment: any = {};
  public showSegment: boolean = false;
  public segmentGroupsValues = null;

  constructor(
    @Inject('environment') public environment,
    private trackingService: TrackingService,
    private toastr: ToastrService,
    public searchTagsService: SearchTagsService,
    public playerService: PlayerService,
    public commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private genericModalService: GenericModalService,
    private searchService: SearchService,
    private searchConfigService: SearchConfigService,
    private router: Router,
  ) {
    RecaptchaComponent.prototype.ngOnDestroy = function () {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
    this.getYoutubeReference = debounce(this.getYoutubeReference, 500);
    this.setSuggestionsObserver();
    this.searchItemsSubscribe();
    this.playerService.isProcessingReference.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.isProcessingReference = value;
      if (this.isProcessingReference === 'end') {
        const ref = JSON.parse(localStorage.getItem('SOSTEREO.Ref-Youtube') || '{}');
        const values: any = Object.values(ref.segmentsGroups);
        this.segmentGroupsValues = ref.segmentsGroups;
        const segment2 = values.length > 1 ? values[1][0] : values[0][0];
        this.segment = { start: segment2.start, duration: segment2.start + segment2.duration };
        this.showSegment = true;
      } else {
        this.segmentGroupsValues = null;
        this.showSegment = false;
      }

      this.changeDetectorRef.markForCheck();
    });

    this.playerService.percentageReference.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.percentageReference = value;
      this.changeDetectorRef.markForCheck();
    });

    this.playerService.externalReference.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.externalAudio = value;
      this.changeDetectorRef.markForCheck();
    });
  }

  public onRecaptchaError(errorDetails: RecaptchaErrorParameters): void {
    this.toastr.error('There was an error with recaptcha');
    this.trackingService.track('Recaptcha error', {
      error: errorDetails,
    });
  }

  toggleFullScreenSearch(open: boolean) {
    this.toggleFullScreenSearchEmit.emit(open);
  }

  getYoutubeId(url) {
    let id = null;
    let patterns = [
      /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
      /\?v=([^#\&\?]{11})/, // ?v=<id>
      /\&v=([^#\&\?]{11})/, // &v=<id>
      /embed\/([^#\&\?]{11})/, // embed/<id>
      /\/v\/([^#\&\?]{11})/, // /v/<id>
    ];
    patterns.forEach((pattern: any) => {
      if (pattern.exec(url)) {
        id = pattern.exec(url)[1];
        return;
      }
    });
    return id;
  }

  public onFullScreenSearchKeyUp(event) {
    if (event.key === 'Escape') {
      this.toggleFullScreenSearch(false);
      return;
    }

    clearTimeout(this.searchTypingTimeout);
    let query = event.target?.value || this.typedSearch;
    if (query.endsWith(' ')) {
      query = query.slice(0, -1);
    }
    this.searchTypingTimeout = setTimeout(() => {
      const spotifyRegex =
        /^https:\/\/open\.spotify\.com\/(?:[a-z]{2}-[a-z]{2}\/)?(?:[^/]+\/)?track\/[a-zA-Z0-9]+(\?[a-zA-Z0-9_=&]+)?$/;

      const matchYoutubeUrl = this.getYoutubeId(query);
      const matchSpotifyUrl = query.match(spotifyRegex);
      if (
        (matchYoutubeUrl || matchSpotifyUrl) &&
        this.commonService.isAllowed(['GetReferenceSearch'])
      ) {
        this.wavesReady = false;
        this.isProcessingReference = 'none';
        this.getYoutubeReference(matchYoutubeUrl ? 'youtube' : 'spotify');
        this.trackingService.track(
          'Youtube Reference Typed',
          { query: query },
          {
            evernt_action: 'Youtube reference typed',
            event_type: 'Tyeped Seach',
            event_value: query,
          },
        );
      } else {
        if (query.length >= 3) {
          this.playerService.isYoutubeReference = false;
          this.getSuggestions(query);
        } else {
          this.suggestions = null;
          this.noResults = false;
        }
      }
      this.atLeastOnePositiveTag = this.searchTagsService.selectedTags.some((tag) => !tag.negative);
      this.changeDetectorRef.detectChanges();
    }, 200);
  }

  private getYoutubeReference(target_type: string) {
    if (this.playerService.isProcessingReference.getValue() === 'processing') {
      this.openGenericModal(target_type);
      return;
    }
    this.suggestionsLoading = true;
    this.changeDetectorRef.detectChanges();
    this.recaptcha.reset();
    this.recaptcha.execute();
    const recaptChaSub = this.recaptcha.resolved.subscribe((data) => {
      if (data) {
        this.showSettings = false;
        const url = clone(this.typedSearch);
        this.playerService.externalReference.next('');
        this.playerService.percentageReference.next('0');
        const videoId = target_type === 'youtube' ? this.getYoutubeId(url) : this.getSpotifyId(url);
        const youtubeSeedTag = this.getYoutubeRefTag({
          name: `Ext. Ref: ${videoId}`,
          id: videoId,
          verifying: true,
        });
        this.searchTagsService.addYoutubeTag(youtubeSeedTag);
        setTimeout(() => {
          recaptChaSub.unsubscribe();
          this.suggestionsLoading = false;
          this.playerService.isYoutubeReference = true;
          this.typedSearch = '';
          this.changeDetectorRef.detectChanges();
        }, 1000);
        this.searchTagsService.getYtReference(videoId, data, { target_type });
      }
    });
  }

  private getSuggestions(query) {
    this.suggestionsLoading = true;
    this.changeDetectorRef.detectChanges();
    let searchNoVocals: any = null;
    let tags = this.searchTagsService.selectedTags
      .filter((t) => t.type !== 'lyric')
      .map((t) => (t?.negative ? '! ' + t.slug : t.slug));
    let lyrictag = this.searchTagsService.selectedTags
      .filter((t) => t.type === 'lyric')
      .map((t) => t.value);
    if (tags.includes('instrumental')) {
      searchNoVocals = true;
      tags = tags.filter((e) => e !== 'instrumental');
    }
    if (tags.includes('full')) {
      searchNoVocals = false;
      tags = tags.filter((e) => e !== 'full');
    }
    const params: any = {
      q: `${query}~`,
      selection: {},
      tags: tags.toString(),
      qLyrics: lyrictag,
      index: 0,
      limit: 20,
      types: [
        'songs',
        'artists',
        'genre',
        'mood',
        'vocal',
        'instrumentation',
        'rhythm',
        'harmony',
        'lyrics-subject',
        'vocal-sonority',
        'themes-uses',
        'bpm',
        'productionstyle',
        'language',
        'lyric',
        'lyric-strict',
        'recording-location',
        'playlists',
      ],
    };
    if (searchNoVocals !== null) {
      params.vocals = !searchNoVocals;
    }
    const useOriginalCatalog = this.searchTagsService.useOriginalCatalog;
    if (useOriginalCatalog && this.subdomain) {
      params.catalog = 'sostereo';
    }
    this.noResults = false;
    this.playlistObserver$.next(params);
  }

  private openGenericModal(target_type: string) {
    this.genericModalService.showModal(
      {
        title: 'Do you want to replace the reference?',
        message:
          'A reference is already being processed. Click replace if you want to start over with this new link.',
        cssClass: 'fa fa-exclamation-triangle',
        size: 'modal-sm',
        btns: [
          {
            status: true,
            cssClass: 'primary-btn',
            title: 'Replace',
            data: { target_type },
          },
          { kind: 'close', title: 'Cancel', cssClass: 'cancel-btn' },
        ],
      },
      this.genericModalOK.bind(this),
    );
  }

  private getSpotifyId(url) {
    let urlParts = url.split('/');
    let songId = urlParts[urlParts.length - 1];
    return songId.split('?')[0];
  }

  public getYoutubeRefTag(videoInfo) {
    return {
      category: 'youtube-ref',
      label: videoInfo.name,
      slug: videoInfo.id,
      type: 'youtube-ref',
      verifying: videoInfo.verifying,
      songData: videoInfo.songData,
    };
  }

  public genericModalOK(event) {
    if (event) {
      this.toastr.clear();
      this.playerService.isProcessingReference.next('none');
      this.searchTagsService.cancelPubNubSubscription();
      this.getYoutubeReference(event.data.target_type);
    }
  }

  private setSuggestionsObserver() {
    this.playlistObserver$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((params) => {
          return this.searchService
            .getSuggestions({ ...params, fields: ['slug', 'thumbUrl'] })
            .pipe(finalize(() => this.changeDetectorRef.detectChanges()));
        }),
      )
      .subscribe((res) => {
        this.suggestionsLoading = false;
        this.suggestions = this.searchTagsService.mapSuggestions(res.data);
        const ixLyric = findIndex(this.suggestions, (sg: any) => sg.id === 'lyric');
        const ixLyricStrict = findIndex(this.suggestions, (sg: any) => sg.id === 'lyric-strict');
        if (ixLyric !== -1) {
          this.suggestions[ixLyric].lyricData = [this.suggestions[ixLyric]];
          if (
            ixLyricStrict !== -1 &&
            this.suggestions[ixLyricStrict] &&
            this.suggestions[ixLyricStrict].items &&
            this.suggestions[ixLyricStrict].items.length > 0
          ) {
            this.suggestions[ixLyric].lyricData.push(this.suggestions[ixLyricStrict]);
            this.suggestions.splice(ixLyricStrict, 1);
          }
        }
        this.noResults = this.searchTagsService.noResults;
        setTimeout(() => {
          const wrapper: any = document.getElementById('tags-wrapper');
          for (const prop in this.heightLimits) {
            this.heightLimits[prop].value = wrapper?.clientHeight - this.heightLimits[prop].margin;
          }
          this.changeDetectorRef.detectChanges();
        });
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const wrapper = document.getElementById('tags-wrapper');
    if (wrapper) {
      for (const prop in this.heightLimits) {
        this.heightLimits[prop].value = wrapper.clientHeight - this.heightLimits[prop].margin;
      }
    }
  }

  toggleTagType(selectedTag: any) {
    this.toggleTagTypeEmit.emit(selectedTag);
  }

  sendSearch() {
    this.sendSearchEmit.emit();
  }

  removeTag(selectedTag: any) {
    this.removeTagEmit.emit(selectedTag);
  }

  processExternalUrl() {
    const spotifyRegex =
      /^https:\/\/open\.spotify\.com\/(?:[a-z]{2}-[a-z]{2}\/)?(?:[^/]+\/)?track\/[a-zA-Z0-9]+(\?[a-zA-Z0-9_=&]+)?$/;
    const matchYoutubeUrl = this.getYoutubeId(this.typedSearch);
    const matchSpotifyUrl = this.typedSearch.match(spotifyRegex);
    const external = this.typedSearch.includes('Ext://');
    if (!this.loggedUser && (matchYoutubeUrl || matchSpotifyUrl || external)) {
      const activeToast = this.toastr.warning(
        'To access our reference search, please log in to your account',
      );
      activeToast.onTap.subscribe((next) => {
        console.log(next);
        this.router.navigateByUrl('/login');
      });
      return;
    }
    if (
      (matchYoutubeUrl || matchSpotifyUrl) &&
      this.commonService.isAllowed(['GetReferenceSearch'])
    ) {
      this.wavesReady = false;
      this.getYoutubeReference(matchYoutubeUrl ? 'youtube' : 'spotify');
    } else if (this.typedSearch.includes('Ext://')) {
      const songId = this.typedSearch.split('Ext://')[1] || '';
      this.getUploadReference(songId);
    }
  }

  onTypedSearchChange() {
    if (this.playerService.editorialSearchParam) {
      this.playerService.goToEditorial = null;
    }
    if (this.typedSearch === '') {
      clearTimeout(this.searchTypingTimeout);
      setTimeout(() => {
        this.suggestions = null;
        this.changeDetectorRef.detectChanges();
      }, 400);
    } else {
      this.processExternalUrl();
      this.changeDetectorRef.detectChanges();
    }
    if (
      this.currentUrl?.includes('/edit-playlist') &&
      this.searchTagsService.selectedTags.some((tag) => tag.category === 'playlist')
    ) {
      this.searchTagsService.selectedTags.forEach((tag) => {
        if (tag.category === 'playlist') {
          this.searchTagsService.removeTag(tag);
        }
      });
    }
    this.atLeastOnePositiveTag = this.searchTagsService.selectedTags.some((tag) => !tag.negative);
  }

  public trackUpdate(track: any) {
    if (track.progressStatus?.id === 'success') {
      let key = track.key || track.fileId;
      key = key.replace('music/', '').replace('.mp3', '');
      this.getUploadReference(key);
    }
  }

  private getUploadReference(songId: string) {
    this.recaptcha.reset();
    this.recaptcha.execute();
    this.suggestionsLoading = true;
    const recaptChaSub = this.recaptcha.resolved.subscribe((data) => {
      if (data) {
        this.showSettings = false;
        const videoId = songId;
        const youtubeSeedTag = this.getYoutubeRefTag({
          name: `Ext. Ref: ${videoId}`,
          id: videoId,
          verifying: true,
        });
        this.searchTagsService.addYoutubeTag(youtubeSeedTag);
        setTimeout(() => {
          recaptChaSub.unsubscribe();
          this.typedSearch = '';
          this.suggestionsLoading = false;
          this.playerService.isYoutubeReference = true;
          this.changeDetectorRef.detectChanges();
        }, 1000);
        this.searchTagsService.getYtReference(videoId, data, {});
      }
    });
  }

  public uploadError(event) {
    this.toastr.clear();
    this.toastr.error(event.error?.error?.message || 'There was an error uploading the file');
  }

  public listenToSimilarSongYT() {
    this.typedSearch = '';
    this.toastr.clear();
    this.searchTagsService.createRefYoutubeReady(this.segment);
    this.trackingService.track(
      'Listen To Similar Songs Click',
      { action: 'click' },
      {
        event_action: 'Listen to similar songs button clicked',
        event_type: 'Button Click',
      },
    );
  }

  public addTagFromAggregations(tag, negative?) {
    const tagId = this.commonService.getSlugFromProperty(tag.label);
    let aggregationTag: any = {};
    switch (tag.category) {
      case 'recording-location':
        aggregationTag = {
          category: tag.category,
          label: `Rec. Location: ${tag.label}`,
          slug: `recording-location-${tagId}`,
          type: tagId,
          value: tag.label,
        };
        break;
      case 'available-markets':
        aggregationTag = {
          category: tag.category,
          label: `Available Market: ${tag.label}`,
          slug: `available-market-${tagId}`,
          type: tagId,
          value: tag.label,
        };
        break;
    }
    this.searchTagsService.addTag(aggregationTag);
    if (negative) {
      this.toggleRecentlyAddedTag(aggregationTag);
    }
    this.typedSearch = '';
    this.suggestions = null;
    const searchInput = document.getElementById('discoverSearchInput') as HTMLInputElement;
    if (searchInput) {
      searchInput.focus();
    }
    this.sendSearch();
    this.toggleFullScreenSearch(false);
    this.changeDetectorRef.detectChanges();
  }

  private toggleRecentlyAddedTag(tag) {
    this.searchTagsService.toggleTagType(tag);
    this.changeDetectorRef.detectChanges();
  }

  public sendSearchWithQueryTag(option) {
    if (this.currentUrl.includes('lyrics=open') && option.id == 'lyric') {
      this.removeLyricsOptionEmit.emit();
    }
    if (option.id === 'playlists') {
      this.playerService.editorialSearchParam = this.typedSearch;
      this.changeDetectorRef.detectChanges();
      this.trackingService.track(
        'Search typed value on search bar (Playlist - Show all)',
        {
          typedValue: this.typedSearch,
          playlists: option.items,
        },
        {
          event_action: 'Search typed value on search bar (Playlist - Show all)',
          event_type: 'Search Bar Type Search',
          event_value: this.typedSearch,
        },
      );
      const baseUrl = this.currentUrl.split('?');
      if (this.currentUrl.includes('edit-playlist')) {
        this.removeEditorialParam = false;
        setTimeout(() => {
          this.removeEditorialParam = true;
        });
      }
      this.router.navigateByUrl(
        `/${
          this.isMobileOrTablet
            ? 'my-playlists'
            : this.currentUrl.includes('edit-playlist')
            ? 'music-sync-licensing/edit-playlist'
            : 'music-sync-licensing/playlist'
        }${!this.isMobileOrTablet ? `/${option.items[0].slug}` : ''}${
          this.isMobileOrTablet
            ? `${baseUrl[1] ? `?${baseUrl[1]}&` : '?'}pls=${
                this.playerService.editorialSearchParam
              }`
            : baseUrl[1]
            ? `?${baseUrl[1]}`
            : ''
        }`,
      );

      this.playerService.showPlaylistMenu.next(true);
      this.changeDetectorRef.detectChanges();
    } else {
      this.addQueryTag(option);
      this.sendSearch();
    }
    this.toggleFullScreenSearch(false);
  }

  public addQueryTag(option) {
    const tagOption = clone(option);
    // document.getElementById('search-bg').scrollIntoView({block: 'start', behavior: 'smooth'});
    const queryTag = this.searchTagsService.selectedTags.find((t) => t.category === 'query');

    if (queryTag) {
      this.searchTagsService.removeTag(queryTag);
    }
    if (tagOption.id === 'lyric') {
      this.typedSearch = this.typedSearch.replace(/"/g, '');
    }
    if (tagOption.id === 'lyric-strict') {
      const getQuotes = this.typedSearch.split(',').map((lyric) => {
        return `"${lyric.trim().replace(/"/g, '')}"`;
      });
      this.typedSearch = getQuotes.toString();
      tagOption.id = 'lyric';
    }
    const typedSearch = this.typedSearch;
    const tag = {
      category: 'query',
      label: `${tagOption.id}: ${typedSearch}`,
      slug: `query-${tagOption.id}-${typedSearch}`,
      type: tagOption.id,
      value: typedSearch,
    };
    this.addTagThroughService(tag);
    this.typedSearch = '';
    this.suggestions = null;
    this.sendSearch();
    this.toggleFullScreenSearch(false);
  }

  public addTagFromSuggestions(tag, negative?: boolean) {
    if (tag.category === 'artists') {
      this.searchTagsService.selectedTags.forEach((selectedTag) => {
        if (selectedTag.category === 'artists') {
          this.searchTagsService.removeTag(selectedTag);
        }
      });
    }
    if (
      (tag.category === 'lyric' || tag.category === 'songs') &&
      this.currentUrl.includes('lyrics=open')
    ) {
      this.removeLyricsOptionEmit.emit();
    }
    if (tag.category === 'playlists') {
      this.playerService.editorialSearchParam = null;
      this.playerService.goToEditorial = true;
      this.trackingService.track(
        'Search typed value on search bar (Single playlist)',
        {
          typedValue: this.typedSearch,
          playlist: tag?.slug || tag?.id,
        },
        {
          event_action: 'Search typed value on search bar (Single playlist)',
          event_type: 'Search Bar Type Search',
          event_value: this.typedSearch,
          element_type: 'Playlist',
          element_value: tag?.slug || tag?.id,
        },
      );
      const baseUrl = this.currentUrl.split('?');
      this.router.navigateByUrl(
        `/${
          this.isMobileOrTablet
            ? 'my-playlists'
            : this.currentUrl.includes('edit-playlist')
            ? 'music-sync-licensing/edit-playlist'
            : 'music-sync-licensing/playlist'
        }/${tag.slug}${baseUrl[1] ? `?${baseUrl[1]}` : ''}`,
      );
      this.playerService.showPlaylistMenu.next(true);
      this.changeDetectorRef.detectChanges();
      this.playerService.goToEditorial = null;
      this.toggleFullScreenSearch(false);
      return;
    }
    tag.negative = negative;
    this.addTagThroughService(tag);
    this.typedSearch = '';
    this.suggestions = null;
    this.updateActiveTabEmit.emit();
    this.sendSearch();
    this.toggleFullScreenSearch(false);
    this.changeDetectorRef.detectChanges();
  }

  private addTagThroughService(tag) {
    this.trackingService.track(
      'Search typed value on search bar',
      {
        typedValue: this.typedSearch,
        tag: tag,
      },
      {
        event_action: 'Search typed value on search bar',
        event_type: 'Search Bar Type Search',
        event_value: this.typedSearch,
      },
    );
    this.searchTagsService.addTag(tag);
    const searchInput = document.getElementById('discoverSearchInput') as HTMLInputElement;
    if (searchInput) {
      searchInput.focus();
    }
    if (this.commonService.isAllowed(['UpdateSearchSettings'], [])) {
      this.toggleMatchPercentage();
    }
  }

  private toggleMatchPercentage() {
    const otherCategoryTags = this.searchTagsService.selectedTags.filter(
      (t) =>
        !t.category ||
        t.category === 'genre' ||
        t.category === 'mood' ||
        t.category === 'vocal' ||
        t.category === 'rhythm' ||
        t.category === 'harmony',
    );
    const minMatchItem = this.getTagMatchOption();
    if (!minMatchItem.userInput) {
      if (otherCategoryTags.length > 4) {
        minMatchItem.value = 75;
      } else {
        minMatchItem.value = 0;
      }
    }
  }

  private getTagMatchOption() {
    return (
      this.searchTagsService.settingsList.find((s) => s.category === 'Random')?.items as any
    )?.find((i) => i.slug === 'minMatch');
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
    this.changeDetectorRef.detectChanges();
  }

  public trackByIndex(index) {
    return index;
  }

  private searchItemsSubscribe() {
    this.searchChangeValue$ = this.playerService.searchChangeValue.subscribe((value) => {
      this.typedSearch = value;
      this.changeDetectorRef.markForCheck();
      this.processExternalUrl();
    });
  }

  public onReadyWaves() {
    this.wavesReady = true;
  }

  public onChangeSegment(segment) {
    this.segment = segment;
  }

  ngOnDestroy(): void {
    this.playerService.externalReference.next('');
    this.playerService.isProcessingReference.next('none');
    this.playerService.searchChangeValue.next('');
    this.playlistObserver$.unsubscribe();
    this.searchChangeValue$?.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

<button
  (click)="removeVersionTag()"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  All
</button>
<button
  (click)="setSearchVersion({ category: 'type', label: 'Instrumental', slug: 'instrumental' })"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  Instrumental
</button>
<button
  (click)="setSearchVersion({ category: 'type', label: 'Full/Vocal', slug: 'full' })"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  Full/vocal
</button>
<button
  (click)="setSingleTag({ category: 'explicit', label: 'Exclude explicit', slug: 'explicit' })"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  Exclude explicit
</button>
<button
  *ngIf="commonService.isAllowed(['*'])"
  (click)="setSingleTag({ category: 'oneStop', label: 'One Stop', slug: 'isOneStop' })"
  (mouseover)="htmlTags.oneStop.hover = true"
  (mouseleave)="htmlTags.oneStop.hover = false"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  <div class="center-flex" style="width: 100%">
    <div class="tag-label">
      <span>One Stop</span>
    </div>
    <div
      *ngIf="
        htmlTags.oneStop.hover ||
        playerService.isSmallSize ||
        htmlTags.oneStop.negative ||
        htmlTags.oneStop.selected
      "
      class="selection-tag-opt"
      [ngClass]="{
        'check-selected-tag-opt': htmlTags.oneStop.selected && !htmlTags.oneStop.negative,
        'exclude-selected-tag-opt': !htmlTags.oneStop.hover && htmlTags.oneStop.negative
      }"
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </div>
    <div
      *ngIf="
        htmlTags.oneStop.hover ||
        playerService.isSmallSize ||
        htmlTags.oneStop.negative ||
        htmlTags.oneStop.selected
      "
      class="selection-tag-opt"
      (click)="
        $event.stopPropagation();
        setSingleTag({ category: 'oneStop', label: 'One Stop', slug: 'isOneStop' }, true)
      "
      [ngClass]="{
        'check-selected-tag-opt':
          !htmlTags.oneStop.hover && htmlTags.oneStop.selected && !htmlTags.oneStop.negative,
        'exclude-selected-tag-opt': htmlTags.oneStop.negative
      }"
    >
      <i class="fa fa-ban" aria-hidden="true"></i>
    </div>
  </div>
</button>
<button
  *ngIf="commonService.isAllowed(['*'])"
  (click)="setSingleTag({ category: 'covers', label: 'Covers', slug: 'isCovers' })"
  (mouseover)="htmlTags.covers.hover = true"
  (mouseleave)="htmlTags.covers.hover = false"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  <div class="center-flex" style="width: 100%">
    <div class="tag-label">
      <span>Covers</span>
    </div>
    <div
      *ngIf="
        htmlTags.covers.hover ||
        playerService.isSmallSize ||
        htmlTags.covers.negative ||
        htmlTags.covers.selected
      "
      class="selection-tag-opt"
      [ngClass]="{
        'check-selected-tag-opt': htmlTags.covers.selected && !htmlTags.covers.negative,
        'exclude-selected-tag-opt': !htmlTags.covers.hover && htmlTags.covers.negative
      }"
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </div>
    <div
      *ngIf="
        htmlTags.covers.hover ||
        playerService.isSmallSize ||
        htmlTags.covers.negative ||
        htmlTags.covers.selected
      "
      class="selection-tag-opt"
      (click)="
        $event.stopPropagation();
        setSingleTag({ category: 'covers', label: 'Covers', slug: 'isCovers' }, true)
      "
      [ngClass]="{
        'check-selected-tag-opt':
          !htmlTags.covers.hover && htmlTags.covers.selected && !htmlTags.covers.negative,
        'exclude-selected-tag-opt': htmlTags.covers.negative
      }"
    >
      <i class="fa fa-ban" aria-hidden="true"></i>
    </div>
  </div>
</button>
<button
  *ngIf="commonService.isAllowed(['*'])"
  (click)="setSingleTag({ category: 'hasRhythm', label: 'Rhythm', slug: 'hasRhythm' })"
  (mouseover)="htmlTags.hasRhythm.hover = true"
  (mouseleave)="htmlTags.hasRhythm.hover = false"
  type="button"
  class="btn tag-btn white-btn overflow-text text-left"
>
  <div class="center-flex" style="width: 100%">
    <div class="tag-label">
      <span>Rhythm</span>
    </div>
    <div
      *ngIf="
        htmlTags.hasRhythm.hover ||
        playerService.isSmallSize ||
        htmlTags.hasRhythm.negative ||
        htmlTags.hasRhythm.selected
      "
      class="selection-tag-opt"
      [ngClass]="{
        'check-selected-tag-opt': htmlTags.hasRhythm.selected && !htmlTags.hasRhythm.negative,
        'exclude-selected-tag-opt': !htmlTags.hasRhythm.hover && htmlTags.hasRhythm.negative
      }"
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </div>
    <div
      *ngIf="
        htmlTags.hasRhythm.hover ||
        playerService.isSmallSize ||
        htmlTags.hasRhythm.negative ||
        htmlTags.hasRhythm.selected
      "
      class="selection-tag-opt"
      (click)="
        $event.stopPropagation();
        setSingleTag({ category: 'hasRhythm', label: 'Rhythm', slug: 'hasRhythm' }, true)
      "
      [ngClass]="{
        'check-selected-tag-opt':
          !htmlTags.hasRhythm.hover && htmlTags.hasRhythm.selected && !htmlTags.hasRhythm.negative,
        'exclude-selected-tag-opt': htmlTags.hasRhythm.negative
      }"
    >
      <i class="fa fa-ban" aria-hidden="true"></i>
    </div>
  </div>
</button>

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { CommonService } from '../../../../shared/services/common.service';
import { searchBarSection } from '../../configs/search-bar-section.config';
import { PlayerService } from '../../../../shared/services/player.service';
import { PlaylistService } from '../../../../shared/services/playlist.service';
import { PlayerWidgetService } from '../../../../layout/services/player-widget.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation, outInAnimation } from '../../../../shared/animations/in-out.animation';
import { clone, isEmpty, sortBy, startCase, remove, cloneDeep } from 'lodash-es';
import {
  AuthenticationService,
  SearchService,
  TagsCategoriesService,
  AgreementService,
  RestrictionsService,
  FileService,
} from 'sostereo-services';
import { SearchTagsService } from '../../../../shared/services/search-tags.service';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CurrencyPipe, Location, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { TermsModalComponent } from '../../../modals/components/terms-modal/terms-modal.component';
import { ManageUrlTagsService } from 'src/app/angular/shared/services/manage-url-tags.service';
import { ToastrService } from 'ngx-toastr';
import {
  getBudgetSliderSettings,
  getStatsSliderSettings,
  getBpmSliderSettings,
  prefixes,
  dropDownSettings,
  multiSelect,
  htmlTags,
} from './config-elements';
import { EditPlaylistModalComponent } from '../../../modals/components/edit-playlist-modal/edit-playlist-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SuggestionSongsService } from 'src/app/angular/shared/services/suggestion-songs.service';
import * as Sentry from '@sentry/browser';
import { SearchConfigService } from 'src/app/angular/shared/services/search-config.service';
import { FileUploadComponent } from 'src/app/angular/shared/components/file-upload/file-upload.component';

@Component({
  selector: 'app-search-sidebar',
  templateUrl: './search-sidebar.component.html',
  styleUrls: ['./search-sidebar.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
})
export class SearchSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('termsModalComponent', { static: true }) termsModal: TermsModalComponent;
  @ViewChild('editPlaylistModal', { static: true }) playlistModal: EditPlaylistModalComponent;
  @ViewChild('fileUploadComponent') fileUpload: FileUploadComponent;

  public currentUrl: string;
  public loading = false;
  public logoSrc = './assets/images/logo.svg';
  public expandedTag: any = null;
  public loggedUser: any;
  public company: any;
  public ratingQueueView = this.router.url.includes('/rating/queue');
  public showTagQuantity = this.router.url.includes('music-sync-licensing/search');
  public tabs: any[] = [];
  public activeTab: any = null;
  public settingsListConfig = cloneDeep(this.searchTagsService.settingsList);
  public budgetSettings = searchBarSection().budgetSettings;
  public advancedSearchSettings = searchBarSection().advancedSearchSettings;
  public budgetSliderSettings = getBudgetSliderSettings(this.currency);
  public statsSliderSettings = getStatsSliderSettings(this.commonService);
  public bpmSliderSettings = getBpmSliderSettings(this.commonService);

  public expandedLoader = false;
  public displayLyrics = false;
  public subdomain: string;
  public agreement: any;
  public prefixes = prefixes;
  public sortType = 'label';
  public tagsCount: any = {};
  public lyricsTag = { label: 'Show lyrics', slug: 'lyrics', hover: false };
  public scrolled = false;
  private bmpTags = [];
  public currentSong = this.playerWidgetService.getCurrentTrack();
  public occuracyCheck = false;
  public sostereoCheck = false;
  public subdomainCheck = false;
  public searchOpen: boolean;
  public showBlocker: boolean;
  public tenantId = this.commonService.tenantId;
  public dropDownSettings = dropDownSettings;
  public agreements: any = [];
  public playlistId = '';
  private showExtendedSearchSidebar: BehaviorSubject<boolean> =
    this.playerService.showExtendedSearchSidebar;

  private showLyrics$: Subscription = new Subscription();
  private showSettings$: Subscription = new Subscription();
  private agreementUpdate$: Subscription = new Subscription();
  private newTrack$: Subscription = new Subscription();
  private searchTags$: Subscription = new Subscription();
  private budgetTagsRemoved$: Subscription = new Subscription();
  private allTagsRemoved$: Subscription = new Subscription();
  private tagRemoved$: Subscription = new Subscription();
  private scrolled$: Subscription = new Subscription();
  private showPlaylistMenuContainer$: Subscription = new Subscription();
  private accessData$: Subscription = new Subscription();
  private updateSelectedTags$: Subscription = new Subscription();
  private genericTagUpdated$: Subscription = new Subscription();
  private playlistNumberUpdate$: Subscription = new Subscription();
  private updatePlaylistMenuCollapse$: Subscription = new Subscription();
  private genericTagsPrevValue;
  public htmlTags = htmlTags;
  public multiSelect = multiSelect;
  private showPlaylistMenu$: Subscription;
  public collapsedSearchSidebar = true;
  private $showPlaylistSettings: Subscription;
  private removeEditorialParam = true;
  public uploadToken = '';
  public isArtistList = true;

  @Output() selectedTag = new EventEmitter();
  public openFullScreenSlugs = [
    { slug: 'lyrics', placeholder: '' },
    { slug: 'refYoutube', placeholder: 'Paste the YouTube video url' },
    { slug: 'refSpotify', placeholder: 'Paste the Spotify song url' },
    { slug: 'refUpload', placeholder: 'MP3' },
  ];

  public searchPlaceholder: string = 'Find the right music for your project';
  public uploadFile: boolean = false;
  private QUEUE_TAGS = 'SOSTEREO_QUEUE_TAGS';
  public isAdmin = this.commonService.isAllowed(['*']);
  public showPlaylistMenu = false;
  public editorialPlaylistTotal = -1;
  public plLibrary = window.location?.pathname?.includes('/edit-playlist');
  public isMobileOrTablet =
    this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();

  isBrowser: boolean;

  constructor(
    public playerService: PlayerService,
    public playlistService: PlaylistService,
    public commonService: CommonService,
    public authenticationService: AuthenticationService,
    public playerWidgetService: PlayerWidgetService,
    private tagsCategoriesService: TagsCategoriesService,
    private changeDetectorRef: ChangeDetectorRef,
    public searchTagsService: SearchTagsService,
    private searchService: SearchService,
    private agreementService: AgreementService,
    private restrictionsService: RestrictionsService,
    private currency: CurrencyPipe,
    private router: Router,
    private location: Location,
    private trackingService: TrackingService,
    private manageUrlTagsService: ManageUrlTagsService,
    private toastr: ToastrService,
    public deviceDetectorService: DeviceDetectorService,
    public suggestionSongService: SuggestionSongsService,
    private searchConfigService: SearchConfigService,
    private fileService: FileService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.ratingQueueView) {
      const tagsArray = ['genres', 'moods', 'vocal', 'rhythm', 'harmony', 'instrumentation'];
      remove(this.tabs, (tb: any) => !tagsArray.includes(tb.slug));
    }
    this.showBlocker = false;
    this.currentUrl = this.router.url;
    this.isArtistList = this.currentUrl.includes('/artists-list');
    this.tabs = this.initializeTabs();
    if (this.currentUrl.includes('lyrics=open')) {
      this.playerService.showLyrics.next(true);
    }
    this.routerSubscribe(router);
    this.getTagsCategories();
    this.loggedUser = this.authenticationService.getCurrentUser();
    this.setSearchForSubdomain();
    this.newTrack$ = this.playerWidgetService.newTrack.subscribe(() => {
      this.currentSong = this.playerWidgetService.getCurrentTrack();
      if (
        this.currentUrl.includes('lyrics=open') ||
        (this.router.routerState.snapshot.root.queryParams.songId &&
          this.currentSong.objectData.hasOwnProperty('lyric') &&
          this.currentSong.objectData.lyric.length > 0) ||
        (this.router.routerState.snapshot.root.queryParams.qFields &&
          this.router.routerState.snapshot.root.queryParams.qFields === 'lyric')
      ) {
        this.displayLyrics = true;
        this.playerService.lyric =
          this.currentSong?.objectData?.lyric?.length > 0
            ? this.currentSong.objectData.lyric
            : null;
        if (this.playerService.isSmallSize) {
          this.searchOpen = true;
        }
        this.playerService.showExtendedSearchSidebar.next(true);
        this.getSongHighlights();
      } else if (!this.activeTab) {
        this.displayLyrics = false;
        this.playerService.lyric = null;
        if (!this.suggestionSongService.showPlaylistSettings.getValue()) {
          this.playerService.showExtendedSearchSidebar.next(false);
        }
      }
    });

    // This is to trigger getEditorialPlaylistCount to update editorial PL number
    this.genericTagUpdated$ = this.searchTagsService.genericTagUpdated.subscribe({
      next: (tags) => {
        const sessionSettings = this.authenticationService.getUserSessionSettings();
        // When the ignore tags flag gets updated but there is no generic tags selected so it shouldn't do anything
        if (this.genericTagsPrevValue === undefined && tags?.includes('updateEditorialPLCount')) {
          return;
        }
        if (
          tags?.length > 0 &&
          (this.genericTagsPrevValue !== tags ||
            sessionSettings?.hasOwnProperty('ignoreTags') ||
            this.editorialPlaylistTotal < 0)
        ) {
          this.genericTagsPrevValue = tags;
          // When there is no generic tags selected the value is undefined and it shouldn't display the playlistCount value
          if (tags === undefined) {
            this.editorialPlaylistTotal = -1;
          } else {
            this.getEditorialPlaylistCount();
          }
        } else {
          this.editorialPlaylistTotal = -1;
        }
      },
    });
    this.playlistNumberUpdate$ = this.playlistService.editorialPLNumberUpdate.subscribe({
      next: (value) => {
        console.log('entro al subscribe playlistnUmber en search');
        if (this.genericTagsPrevValue !== undefined) {
          this.editorialPlaylistTotal = value;
        }
      },
    });

    this.updatePlaylistMenuCollapse$ = this.playlistService.playlistMenuCollapsed.subscribe({
      next: (data: any) => {
        this.collapsedSearchSidebar = data.isCollapsed;
        if (data.isCollapsed) {
          this.closeTab();
        }
      },
    });
  }

  private routerSubscribe(router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isArtistList = event.url.includes('/artists-list');
        if (this.currentUrl.includes('/artists-list')) {
          this.currentUrl = event.url;
          this.closeTab();
          return;
        }
        if (!event.url.includes('lyrics=open')) {
          this.displayLyrics = false;
          this.playerService.showLyrics.next(false);
          this.closeTab();
        }

        if (event.url.includes('/playlist/') || event.url.includes('/mixtape/')) {
          this.searchTagsService.clearTags();
        }
        const urlBase = event.url.split('?')[0];
        if (
          event.url.includes('playlist=open') ||
          urlBase.endsWith('/playlist') ||
          event.url.includes('/edit-playlist')
        ) {
          this.playerService.showPlaylistMenu.next(true);
          this.showPlaylistMenu = true;
          this.changeDetectorRef.detectChanges();
        }
        if (!event.url.includes('/edit-playlist')) {
          this.playlistService.playlistMenuCollapsed.next({ isCollapsed: false });
        }
        this.plLibrary = window.location.pathname.includes('/edit-playlist');
        this.searchOpen = event.url === '/search';
        this.showBlocker = event.url === '/search';
        this.showTagQuantity = event.url.includes('music-sync-licensing/search');
        this.currentUrl = event.url;
        const params = this.router.routerState.snapshot.root.queryParams;
        this.manageUrlTagsService.setTagsByUrl(params, this.currentUrl);
        if (
          this.playerService.editorialSearchParam &&
          window.location.pathname.includes('/edit-playlist') &&
          this.removeEditorialParam
        ) {
          this.playerService.editorialSearchParam = null;
          this.removeEditorialParam = true;
        }
      }
    });
  }

  private getTagsCategories() {
    this.tagsCategoriesService.query({ limit: searchBarSection().defaultTagsQueryLimit }).subscribe(
      (res) => {
        this.tabs.forEach((tab) => {
          if (tab.slug === 'budget' && this.subdomain) {
            tab.tooltip = `Budget search does not apply if you have the custom terms for ${startCase(
              this.subdomain,
            )} enabled. You can use it if you change the config at catalog/terms tab`;
          }
          if (res.data[tab.slug]) {
            tab.tags = res.data[tab.slug].items;
          }
          if (tab.slug === 'vocal' && !this.ratingQueueView) {
            tab.tags.push({ slug: 'lyrics', label: 'Lyrics' });
          }
          if (tab.slug === 'bpm') {
            this.bmpTags = tab.tags;
          }
        });

        const oldTags = localStorage.getItem(this.QUEUE_TAGS);
        const queueParams = oldTags ? JSON.parse(oldTags) : [];

        const params = this.ratingQueueView
          ? { tags: queueParams.join(',') }
          : this.router.routerState.snapshot.root.queryParams;
        this.manageUrlTagsService.initObjectTagsBySlug(this.tabs, params, this.currentUrl);
      },
      (error) => console.log(error),
    );
  }

  public updateExplicitSearch(setting, idx1, idx2) {
    this.searchTagsService.settingsList[idx1].items[idx2].value = setting.model
      ? `explicit=${setting.model}`
      : null;
    this.searchTagsService.eventSettingList.next();
    this.sendSearch();
  }

  private loadRestrictions() {
    this.restrictionsService.query({ limit: 100, sort: 'name asc' }).subscribe((rRes) => {
      let restrictions = rRes.data.items;
      const restrictionsTags = restrictions.map((rt) => ({
        _id: rt._id,
        slug: rt.slug,
        label: rt.name,
        category: 'restrictions',
        visible: rt.visible,
      }));
      if (!this.commonService.isAllowed(['CreateRestriction', '*'])) {
        remove(restrictionsTags, (r: any) => r.hasOwnProperty('visible') && !r.visible);
      }
      this.tabs.find((tb) => tb.slug === 'restrictions').tags = restrictionsTags;
      const params = this.router.routerState.snapshot.root.queryParams;
      this.manageUrlTagsService.addTagsBySlug(restrictionsTags);
      this.manageUrlTagsService.setTagsByUrl(params, this.currentUrl);
    });
  }

  private loadTiers(tabs: any) {
    this.commonService.getTierList().subscribe((tiers) => {
      const tiersTags = tiers;
      tabs.find((tb) => tb.slug === 'tiers').tags = tiersTags;
      const params = this.router.routerState.snapshot.root.queryParams;
      this.manageUrlTagsService.addTagsBySlug(tiersTags);
      this.manageUrlTagsService.setTagsByUrl(params, this.currentUrl);
    });
  }

  public openSection(slug: string, name: string) {
    this.tabs.forEach((t) => (t.active = t.slug === slug));
    this.activeTab = {
      name,
      slug,
      active: true,
      tagIndexLimit: 30,
      tags: [],
    };
    this.showExtendedSearchSidebar.next(true);
  }

  private getSongHighlights() {
    const song = this.currentSong.objectData;
    if (song.hasOwnProperty('highlight') && !isEmpty(song.highlight)) {
      song.highlight['versions.lyric'].forEach((fragment) => {
        const replaceFragment = fragment
          .replace(new RegExp('</em>', 'g'), '')
          .replace(new RegExp('<em>', 'g'), '')
          .replace(/([.*+#?^=!:${}()|\[\]\/\\])/g, '\\$1');
        this.playerService.lyric = this.playerService.lyric.replace(
          new RegExp(replaceFragment, 'g'),
          fragment.replace(new RegExp('<em>', 'g'), '<em class="secondary-color">'),
        );
      });
    }
  }

  private setSearchForSubdomain() {
    this.subdomain = this.commonService.getSubdomain();
    if (this.subdomain) {
      this.logoSrc = this.searchTagsService.searchLogoSrc;
      this.commonService.getCompany(this.subdomain, (company) => {
        if (company) {
          this.tenantId = this.commonService.tenantId;
          this.company = company;

          // Allow License Managers, License requesters but disable  Restricted License requeters
          if (
            this.commonService.isAllowed(
              ['UpdateTransaction:LicenseRequester', 'UpdateTransaction'],
              ['UpdateTransaction:RestrictedLicenseRequester'],
            )
          ) {
            const catalogsTab = {
              name: 'Catalog/Terms',
              slug: 'catalogs',
              active: false,
              tooltip: "Remember you are currently at SoStereo's catalog",
            };
            if (
              this.company.customAgreement &&
              !this.tabs.some((element) => element.slug == 'catalogs')
            ) {
              this.tabs.unshift(catalogsTab);
            }
          }
        }
      });
    }
    if (!this.company && !this.subdomain && this.commonService.isAllowed(['*'])) {
      const brandsTab = {
        name: 'Brands',
        slug: 'brands',
        active: false,
        tooltip: 'Remember you are currently in another catalog',
      };
      const idx = this.tabs.findIndex((tab) => tab.slug === 'settings');
      if (idx !== -1) {
        this.tabs.splice(idx, 0, brandsTab);
      }
    }
    if (!this.subdomain && this.commonService.isAllowed(['*'])) {
      this.getAgreementCompanies();
    }
    if (this.subdomain && this.commonService.isAllowed(['GetAgreement'], ['*'])) {
      this.searchTagsService.getUserAgreement();
    }
  }

  private getAgreementCompanies() {
    this.agreementService.query({ limit: 50, status: 'published' }).subscribe(
      (cRes) => {
        if (cRes?.data?.items.length > 0) {
          this.agreements = sortBy(cRes?.data?.items, [(agr) => agr.name.toLowerCase()]);
          if (this.searchTagsService.useOriginalCatalog && this.commonService.isAllowed(['*'])) {
            let params = clone(this.router.routerState.snapshot.root.queryParams);
            const idx = this.agreements.findIndex(
              (ag) =>
                ag.groups.join() === (this.searchTagsService.currentCatalog || params?.catalog),
            );
            if (idx !== -1) {
              if (!this.searchTagsService.currentCatalog) {
                this.searchTagsService.currentCatalog = params?.catalog;
              }
              this.agreements[idx].check = true;
            }
          }
        }
      },
      (cError) => {
        this.trackingService.track('agreementService error', {
          error: cError,
        });
      },
    );
  }

  ngOnInit() {
    console.log('search ngOnInit');
    this.playerService.showPlaylistMenu.subscribe((value) => {
      this.showPlaylistMenu = value;
      this.changeDetectorRef.detectChanges();
    });

    this.playerService.hideSearchTab.subscribe((value: boolean) => {
      if (value) {
        this.closeTab(true);
        this.changeDetectorRef.detectChanges();
        this.playerService.hideSearchTab.next(false);
      }
    });
    //This is for the playlist menu to be always opened when user is on playlist tab
    //'playlist=open' is not curreltly used, but we keep it for open the editorial playlists on the left side if needed (remarketing or any other use)
    if (this.currentUrl.includes('playlist=open') || this.currentUrl.includes('/edit-playlist')) {
      this.playerService.showPlaylistMenu.next(true);
      this.showPlaylistMenu = true;
      this.changeDetectorRef.detectChanges();
    }
    this.searchOpen = this.router.url === '/search';
    this.showBlocker = this.router.url === '/search';
    this.agreementUpdate$ = this.searchTagsService.agreementUpdate.subscribe(() => {
      this.agreement = this.searchTagsService.agreement;
    });
    this.showLyrics$ = this.playerService.showLyrics.subscribe((value) => {
      this.displayLyrics = value;
      if (value) {
        if (
          window.location.href.includes('lyrics=open') &&
          !this.currentUrl.includes('lyrics=open')
        ) {
          this.currentUrl = this.currentUrl.concat('&lyrics=open');
        }
        this.openSection('lyrics', 'Lyrics');
      }
    });
    this.showSettings$ = this.playerService.showSettings.subscribe((value) => {
      if (value) {
        this.openSection('settings', 'Settings');
      }
    });
    this.$showPlaylistSettings = this.suggestionSongService.showPlaylistSettings.subscribe(
      (value) => {
        if (value) {
          this.closeTab(true);
          this.displayLyrics = false;
          this.expandedTag = null;
        }
        this.showExtendedSearchSidebar.next(value);
        this.changeDetectorRef.detectChanges();
      },
    );
    this.searchTags$ = this.playerService.searchTags.subscribe((tagsCount) => {
      if (!isEmpty(tagsCount)) {
        this.tabs.forEach((t) => (t.countUpdated = false));
        this.tagsCount = tagsCount;
        this.reviewSelectedTabTags(this.expandedTag);
      }
    });

    this.allTagsRemoved$ = this.searchTagsService.allTagsRemoved.subscribe(() => {
      this.resetBudgetTab();
    });

    this.budgetSliderSettings = Object.assign({}, this.budgetSliderSettings, { disabled: true });
    this.budgetTagsRemoved$ = this.searchTagsService.budgetTagsRemoved.subscribe(() => {
      this.budgetSettings.dropDownOptions.forEach((ddo) => {
        ddo.model = [];
        ddo.dropdownSettings.disabled = false;
      });
      this.budgetSliderSettings = Object.assign({}, this.budgetSliderSettings, { disabled: true });
      this.budgetSettings.amount = 15000;
    });

    this.tagRemoved$ = this.searchTagsService.tagRemoved.subscribe((tag) => {
      if (tag.category === 'budget' && tag.type === 'budgetOption') {
        const budgetOptionId = tag.slug.split('.')[0];
        const budgetOption = this.budgetSettings.dropDownOptions.find(
          (ddo) => ddo.id === budgetOptionId,
        );
        const optionSelected = budgetOption.model.find((bo) => bo.id === tag.slug);
        if (optionSelected) {
          budgetOption.model.splice(budgetOption.model.indexOf(optionSelected), 1);
          this.resetBudgetOptions(budgetOption);
        }
      }
      if (tag.category === 'bpm') {
        this.advancedSearchSettings.minBpm.value = this.advancedSearchSettings.minBpm.defaultValue;
        this.advancedSearchSettings.maxBpm.value = this.advancedSearchSettings.maxBpm.defaultValue;
        this.bpmSliderSettings = Object.assign({}, this.bpmSliderSettings, { disabled: true });
      }
      if (tag.category === 'stats') {
        this.advancedSearchSettings.minStats.value =
          this.advancedSearchSettings.minStats.defaultValue;
        this.advancedSearchSettings.maxStats.value =
          this.advancedSearchSettings.maxStats.defaultValue;
        this.statsSliderSettings = Object.assign({}, this.statsSliderSettings, { disabled: true });
      }
      const tagToRemove = this.activeTab?.tags?.find((tagEx) => tagEx.slug == tag.slug);
      if (tagToRemove) {
        tagToRemove.selected = false;
        tagToRemove.selectedNegative = false;
      }
    });

    this.updateSelectedTags$ = this.searchTagsService.updateSelectedTags.subscribe(() => {
      this.updateActiveTab();
    });

    this.scrolled$ = this.playerService.scrolled.subscribe((value) => {
      this.scrolled = value;
    });

    this.showPlaylistMenuContainer$ = this.playlistService.playlistMenuContainer.subscribe(
      (data: any) => {
        if (Object.keys(data).length > 0) {
          this.showPlaylistMenu = data.display;
          this.playlistId = data.playlist;
          this.changeDetectorRef.detectChanges();
        }
      },
    );

    this.accessData$ = this.authenticationService.accessData.subscribe(() => {
      this.loggedUser = this.authenticationService.getCurrentUser();
      this.isAdmin = this.commonService.isAllowed(['*']);
      this.getAdminTabs(this.tabs);
      this.setSearchForSubdomain();
    });
  }

  private getAdminTabs(tabs) {
    if (this.isAdmin) {
      const restrictionsTab: any = {
        name: 'Allowed use',
        slug: 'restrictions',
        active: false,
        tags: [],
      };
      const idx = tabs.findIndex((tb) => tb.slug === 'moods');
      if (idx !== -1) {
        tabs.splice(idx + 1, 0, restrictionsTab);
        this.loadRestrictions();
      }
    }
    if (this.commonService.isAllowed(['ListTiers'])) {
      const tiersTab: any = { name: 'Tiers', slug: 'tiers', active: false, tags: [] };
      const idx = tabs.findIndex((tb) => tb.slug === 'settings');
      tabs.splice(idx > 0 ? idx : tabs.length - 1, 0, tiersTab);
      this.loadTiers(tabs);
    }
  }

  private resetBudgetOptions(budgetOption) {
    if (budgetOption.model.length === 0) {
      const budgetAmountTag = this.manageUrlTagsService.getBudgetAmountTag(
        this.budgetSettings.amount,
      );
      this.searchTagsService.removeTag(budgetAmountTag);
      this.resetBudgetTab();
    }
  }

  private resetBudgetTab() {
    this.budgetSettings.dropDownOptions.forEach((ddo) => {
      ddo.model = [];
      ddo.dropdownSettings.disabled = false;
    });
    this.budgetSliderSettings = Object.assign({}, this.budgetSliderSettings, { disabled: true });
    this.budgetSettings.amount = 15000;
  }

  public reviewSelectedTabTags(expanded?) {
    if (this.activeTab) {
      this.activeTab.countUpdated = true;
    }
    (expanded
      ? this.expandedTag.tags
      : this.activeTab &&
        this.activeTab?.slug !== 'restrictions' &&
        this.activeTab?.slug !== 'tiers' &&
        this.activeTab.tags
      ? this.activeTab.tags
      : []
    ).forEach((tag) => {
      tag.disable =
        this.searchTagsService.selectedTags.length === 0
          ? false
          : !isEmpty(this.tagsCount) && !this.tagsCount[tag.slug];
    });
  }

  public initializeTabs() {
    let tabs = searchBarSection().tabs.filter((t) => !['agreement'].includes(t.slug));
    //Remove unnecesary tabs for Artists List view

    // Remove default active flag from genre tab
    tabs[0].active = false;
    if (!this.ratingQueueView) {
      const songTypeTab = { name: 'Song type', slug: 'song-type', active: false };
      const followersTab = { name: 'Followers', slug: 'followers', active: false };
      const bpmTab: any = { name: 'BPM', slug: 'bpm', active: false, tags: this.bmpTags };
      const settingsTab = {
        name: 'Settings',
        slug: 'settings',
        active: false,
        tooltip: 'The occuracy changes depending on the quantity of tags selected',
      };
      tabs.unshift(songTypeTab);
      tabs.splice(tabs.length - 1, 0, followersTab, bpmTab, settingsTab);

      this.getAdminTabs(tabs);
    }
    return tabs;
  }

  public toggleFullScreenSearch(state, placeholder?: string) {
    if (this.loggedUser || !placeholder) {
      if (this.commonService.isAllowed(['GetReferenceSearch'], []) || !placeholder) {
        this.playerService.showFullScreenSearch = state;
        this.searchPlaceholder = placeholder || 'Find the right music for your project';
        this.uploadFile = placeholder === 'MP3';
        if (this.uploadFile) {
          this.getUploadToken();
        }
        this.playerService.isYoutubeReference = !!placeholder;

        if (state) {
          setTimeout(() => {
            // this will make the execution after the above boolean has changed
            document.getElementById('searchSidebarInput')?.focus();
          }, 0);
          if (this.currentUrl?.includes('/edit-playlist')) {
            this.searchTagsService.selectedTags.forEach((tag: any) => {
              if (tag.category === 'playlist' && state) {
                this.searchTagsService.removeTag(tag);
              }
            });
          }
        }
        this.changeDetectorRef.detectChanges();
      } else {
        this.toastr.warning('You are not allowed to use this feature');
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  public sendSearch() {
    //TODO: I'm removing this because the extended menu keeps closing
    //if (this.playerService.isSmallSize) {
    this.closeTab();
    //}

    const filter = this.searchTagsService.selectedTags.filter(
      (t: any) =>
        t.category &&
        (t.category === 'genre' ||
          t.category === 'mood' ||
          t.category === 'vocal' ||
          t.category === 'rhythm' ||
          t.category === 'harmony'),
    );
    this.occuracyCheck = filter.length > 3;
    this.searchTagsService.sendSearch(null, this.currentUrl.includes('lyrics=open'));
  }

  public changeTab(tab) {
    if (this.suggestionSongService.showPlaylistSettings.getValue()) {
      this.suggestionSongService.showPlaylistSettings.next(false);
    }
    const currentActive = this.tabs.find((t) => t.active);
    if (currentActive && currentActive.slug === tab.slug) {
      this.closeTab();
      return;
    }
    this.expandedTag = null;
    this.tabs.forEach((t) => (t.active = false));
    tab.active = !tab.active;
    if (this.displayLyrics) {
      this.displayLyrics = false;
    }
    if (tab.active) {
      this.activeTab = tab;
      if (
        this.activeTab.hasOwnProperty('countUpdated') &&
        !this.activeTab.countUpdated &&
        !isEmpty(this.tagsCount)
      ) {
        this.reviewSelectedTabTags();
      }
      this.updateActiveTab();
      this.showExtendedSearchSidebar.next(true);
      this.trackingService.track(
        'Filters Tab Selected',
        {
          tab: tab?.name,
          version: 1.0,
        },
        {
          event_action: 'Search bar tab selected',
          event_type: 'Search Bar Click',
          event_value: tab.name,
        },
      );
      if (this.activeTab && this.activeTab.tags && !this.activeTab.preTags) {
        this.activeTab.tags = this.sortTags(this.activeTab.tags);
      }
    }
    if (this.activeTab.slug === 'bpm') {
      this.getSubText();
    }
  }

  private updateActiveTab(expanded?) {
    console.log(
      'Update active tag >>>> ',
      this.activeTab,
      expanded,
      this.searchTagsService.selectedTags,
    );
    const data = !expanded ? this.activeTab : expanded;
    // This is essentially for the 'All' tag
    if (expanded && (data.hasOwnProperty('selected') || data.hasOwnProperty('selectedNegative'))) {
      data.selected = this.searchTagsService.selectedTags.find(
        (st: any) => st.slug === data.slug && st.hasOwnProperty('negative') && !st.negative,
      )
        ? true
        : false;
      data.selectedNegative = this.searchTagsService.selectedTags.find(
        (st: any) => st.slug === data.slug && st.hasOwnProperty('negative') && st.negative,
      )
        ? true
        : false;
    }
    if (data && data.tags) {
      data.tags.forEach((tag) => {
        tag.selected = this.searchTagsService.selectedTags.find(
          (st: any) => st.slug === tag.slug && st.hasOwnProperty('negative') && !st.negative,
        )
          ? true
          : false;
        tag.selectedNegative = this.searchTagsService.selectedTags.find(
          (st: any) => st.slug === tag.slug && st.hasOwnProperty('negative') && st.negative,
        )
          ? true
          : false;
      });
    }
    if (data?.slug == 'song-type') {
      const tagCovers: any = this.searchTagsService.selectedTags.find(
        (st: any) => st.slug === 'isCovers',
      );
      this.htmlTags.covers.selected = tagCovers?.selected || tagCovers?.selectedNegative || false;
      this.htmlTags.covers.negative = tagCovers?.negative || false;

      const tagOneStop: any = this.searchTagsService.selectedTags.find(
        (st: any) => st.slug === 'isOneStop',
      );
      this.htmlTags.oneStop.selected =
        tagOneStop?.selected || tagOneStop?.selectedNegative || false;
      this.htmlTags.oneStop.negative = tagOneStop?.negative || false;

      const tagRhythm: any = this.searchTagsService.selectedTags.find(
        (st: any) => st.slug === 'hasRhythm',
      );
      this.htmlTags.hasRhythm.selected =
        tagRhythm?.selected || tagRhythm?.selectedNegative || false;
      this.htmlTags.hasRhythm.negative = tagRhythm?.negative || false;
    }
  }

  public goBackToParentTag() {
    if (this.expandedTag.parentTag) {
      const parentTag = this.expandedTag.parentTag;
      this.expandedTag = parentTag;
      this.expandedTag.tags = this.sortTags(parentTag.items);
      this.expandedTag.total = parentTag.items.length;
    } else {
      this.expandedTag = null;
      this.activeTab.tags = this.sortTags(this.activeTab.tags);
    }
    this.reviewSelectedTabTags(this.expandedTag);
    this.updateActiveTab(this.expandedTag ? this.expandedTag : null);
  }

  public expandTag(tag) {
    tag.hover = false;
    console.log('expand tag >>>', tag, this.expandedTag);
    let parentTag = [];
    if (this.expandedTag) {
      parentTag = this.expandedTag;
    }
    this.expandedTag = tag;
    this.expandedTag.parentTag = !isEmpty(parentTag) ? parentTag : null;
    this.expandedTag.tags = this.sortTags(tag.items);
    this.expandedTag.total = tag.items.length;
    this.getSubText(this.expandedTag); // This is to remove the text that is redundant
    this.reviewSelectedTabTags(this.expandedTag);
    this.updateActiveTab(this.expandedTag ? this.expandedTag : null);
    if (this.suggestionSongService.showPlaylistSettings.getValue()) {
      this.suggestionSongService.showPlaylistSettings.next(false);
    }
  }

  private getSubText(expandedTag?) {
    if (
      (this.activeTab.slug === 'rhythm' ||
        this.activeTab.slug === 'vocal' ||
        this.activeTab.slug === 'harmony' ||
        this.activeTab.slug === 'bpm') &&
      !this.prefixes[this.activeTab.slug][expandedTag ? expandedTag.slug : this.activeTab.slug]
        .removed
    ) {
      (this.activeTab.slug === 'bpm' ? this.activeTab : expandedTag).tags.forEach((tagElm) => {
        tagElm.updatedLabel = tagElm.label.replace(
          this.prefixes[this.activeTab.slug][expandedTag ? expandedTag.slug : this.activeTab.slug]
            .label,
          '',
        );
      });
      this.prefixes[this.activeTab.slug][
        expandedTag ? expandedTag.slug : this.activeTab.slug
      ].removed = true;
    }
  }

  public closeTab(keepSettings?: boolean) {
    this.tabs.forEach((t) => (t.active = false));
    this.activeTab = null;
    this.showExtendedSearchSidebar.next(false);
    if (!keepSettings) {
      this.suggestionSongService.showPlaylistSettings.next(false);
    }
    if (this.playerService.isSmallSize) {
      this.searchOpen = false;
      this.showBlocker = false;
    }
  }

  public tagIsSelected(tag, isNegative?): boolean {
    const existingTag = this.searchTagsService.selectedTags.find(
      (t: any) => t.slug === tag.slug && t.category === tag.category && isNegative === t.negative,
    );
    return !!existingTag;
  }

  public toggleTagSelection(tag, isNegative) {
    if (this.tagIsSelected(tag, isNegative)) {
      this.searchTagsService.removeTag(clone(tag));
      if (this.ratingQueueView) {
        this.selectedTag.emit({ tag: tag, isNegative: isNegative, remove: true });
      } else {
        this.sendSearch();
      }
      return;
    }

    tag.selectedNegative = !!isNegative;
    tag.selected = !!!isNegative;
    tag.negative = isNegative;
    const existingTag = this.searchTagsService.selectedTags.find(
      (t: any) => t.slug === tag.slug && t.category === tag.category && isNegative !== t.negative,
    );
    let selectedTag = clone(tag);
    if (existingTag) {
      this.toggleTagType(selectedTag);
    } else {
      this.searchTagsService.addTag(selectedTag);
    }
    if (this.ratingQueueView) {
      this.selectedTag.emit({ tag: tag, isNegative: isNegative });
    } else {
      this.sendSearch();
    }
  }

  public removeTag(tag) {
    if (tag.type && tag.type === 'lyric') {
      if (!this.activeTab) {
        this.closeTab();
      }
      this.playerService.showLyrics.next(false);
    }
    if (tag.category === 'advanced-search' && tag.slug !== 'embeddingsVersion') {
      this.searchTagsService.settingsList[tag.idx1].items[tag.idx2] = cloneDeep(
        this.settingsListConfig[tag.idx1].items[tag.idx2],
      );
      this.searchTagsService.eventSettingList.next();
    }
    if (tag.slug === 'embeddingsVersion') {
      this.searchTagsService.embeddedVersion = undefined;
      this.searchTagsService.eventSettingList.next();
    }

    this.searchTagsService.removeTag(tag);
    if (
      tag.category !== 'artists' &&
      tag.category !== 'type' &&
      tag.category !== 'stats' &&
      tag.category !== 'budget' &&
      tag.category !== 'youtube-ref' &&
      tag.category !== 'advanced-search' &&
      tag.category !== 'explicit' &&
      tag.category !== 'ref-song'
    ) {
      this.updateActiveTab(this.expandedTag ? this.expandedTag : null);
    }
    if (this.ratingQueueView) {
      this.selectedTag.emit({ tag: tag, remove: true });
    } else {
      this.sendSearch();
    }
  }

  public toggleTagType(tag) {
    const categoriesWithoutNegative = this.searchConfigService.getCategoriesWithoutNegative();
    if (!categoriesWithoutNegative.includes(tag.category)) {
      if (tag.category !== 'restrictions' && !this.isArtistList) {
        this.searchTagsService.toggleTagType(tag);
        if (this.ratingQueueView) {
          this.selectedTag.emit({ tag: tag, isNegative: tag.negative });
        }
      } else {
        this.searchTagsService.removeTag(tag);
      }
      this.updateActiveTab(this.expandedTag ? this.expandedTag : null);
    } else {
      if (tag.category === 'advanced-search') {
        this.searchTagsService.settingsList[tag.idx1].items[tag.idx2] = cloneDeep(
          this.settingsListConfig[tag.idx1].items[tag.idx2],
        );
        this.searchTagsService.eventSettingList.next();
      }
      this.searchTagsService.removeTag(tag);
    }
  }

  public onBudgetOptionSelect(dropDownOption, event) {
    const budgetTag = {
      category: 'budget',
      label: `${dropDownOption.label} (${event.itemName})`,
      slug: event.id,
      type: 'budgetOption',
    };
    this.searchTagsService.addBudgetOptionTag(budgetTag);
    const budgetAmountTag = this.manageUrlTagsService.getBudgetAmountTag(
      this.budgetSettings.amount,
    );
    this.searchTagsService.addBudgetAmountTag(budgetAmountTag);
    this.budgetSettings.dropDownOptions.forEach((ddo) => {
      ddo.dropdownSettings.disabled = ddo.id !== dropDownOption.id;
    });
    this.budgetSliderSettings = Object.assign({}, this.budgetSliderSettings, { disabled: false });
    this.sendSearch();
    this.trackingService.track(
      'Tag Added',
      {
        user: this.authenticationService.getCurrentUser(),
        tag: budgetTag,
        budgetAmount: budgetAmountTag,
      },
      {
        event_action: 'Tag added to search',
        event_type: 'Tag Added',
        element_type: 'Budget',
        element_value: budgetTag.label,
        element_item_type: 'Slug',
        elemnt_item_name: budgetAmountTag.slug,
      },
    );
  }

  public onBudgetOptionDeSelect(dropDownOption, event) {
    const budgetTag = {
      category: 'budget',
      label: `${dropDownOption.label} (${event.itemName})`,
      slug: event.id,
      type: 'budgetOption',
    };
    this.searchTagsService.removeTag(budgetTag);
    if (dropDownOption.model.length === 0) {
      this.budgetSettings.dropDownOptions.forEach((ddo) => {
        ddo.dropdownSettings.disabled = false;
      });
      const budgetAmountTag = this.manageUrlTagsService.getBudgetAmountTag(
        this.budgetSettings.amount,
      );
      this.searchTagsService.removeTag(budgetAmountTag);
      this.budgetSliderSettings = Object.assign({}, this.budgetSliderSettings, { disabled: true });
      this.budgetSettings.amount = 15000;
    }
    this.sendSearch();
  }

  public onBudgetAmountChange() {
    const budgetAmountTag = this.manageUrlTagsService.getBudgetAmountTag(
      this.budgetSettings.amount,
    );
    this.searchTagsService.addBudgetAmountTag(budgetAmountTag);
    this.sendSearch();
    this.trackingService.track(
      'Budget Change',
      { budgetAmountTag },
      {
        event_action: 'Budget change',
        event_type: 'Budget',
        event_value: budgetAmountTag?.label,
      },
    );
  }

  public onStatsOptionSelect() {
    this.statsSliderSettings = Object.assign({}, this.statsSliderSettings, { disabled: false });
    const statsTag = this.manageUrlTagsService.createStatsRangeTag(
      this.advancedSearchSettings.minStats.value,
      this.advancedSearchSettings.maxStats.value,
      this.advancedSearchSettings.statsSource[0],
    );
    this.searchTagsService.addStatsTag(statsTag);
    this.sendSearch();
  }

  public onStatsOptionDeselect(event) {
    const statsTag = {
      category: 'stats',
      slug: `stats-range-${event.id}`,
      type: event.id,
    };
    if (this.advancedSearchSettings.statsSource.length === 0) {
      this.statsSliderSettings = Object.assign({}, this.statsSliderSettings, { disabled: true });
    }
    this.searchTagsService.removeTag(statsTag);
    this.sendSearch();
  }

  public enableStatsSlider() {
    if (this.advancedSearchSettings.statsSource.length > 0) {
      this.statsSliderSettings = Object.assign({}, this.statsSliderSettings, { disabled: false });
      const statsTag = this.manageUrlTagsService.createStatsRangeTag(
        this.advancedSearchSettings.minStats.value,
        this.advancedSearchSettings.maxStats.value,
        this.advancedSearchSettings.statsSource[0],
      );
      this.searchTagsService.addStatsTag(statsTag);
      this.sendSearch();
    }
  }

  public onStatsSliderChange() {
    const statsTag = this.manageUrlTagsService.createStatsRangeTag(
      this.advancedSearchSettings.minStats.value,
      this.advancedSearchSettings.maxStats.value,
      this.advancedSearchSettings.statsSource[0],
    );
    this.searchTagsService.addStatsTag(statsTag);
  }

  public onBpmSliderChange() {
    const bpmTag = this.manageUrlTagsService.createBpmRangeTag(
      this.advancedSearchSettings.minBpm.value,
      this.advancedSearchSettings.maxBpm.value,
    );
    this.searchTagsService.addBpmRangeTag(bpmTag);
  }

  public enableBpmSlider() {
    this.bpmSliderSettings = Object.assign({}, this.bpmSliderSettings, { disabled: false });
    const bpmTag = this.manageUrlTagsService.createBpmRangeTag(
      this.advancedSearchSettings.minBpm.value,
      this.advancedSearchSettings.maxBpm.value,
    );
    this.searchTagsService.addBpmRangeTag(bpmTag);
    this.sendSearch();
  }

  public closeLyric() {
    this.playerService.lyric = null;
    this.playerService.showLyrics.next(false);
  }

  public trackByIndex(index) {
    return index;
  }

  public navigate(omitParams = false) {
    this.trackingService.track(
      'Filters Tab Selected',
      {
        tab: 'Discover',
        version: 1.0,
      },
      {
        event_action: 'Search bar tab selected',
        event_type: 'Search Bar Click',
        event_value: 'Discover',
      },
    );
    const params = omitParams ? {} : this.router.routerState.snapshot.root.queryParams;
    if (this.currentUrl.includes('music-sync-licensing')) {
      this.closeTab();
      this.router.navigate(['/discover'], { queryParams: params });
    } else {
      this.router.navigate(['/music-sync-licensing/search'], { queryParams: params });
    }
  }

  public changeCatalog(setOriginal: boolean, subdomain, agreementCompany?) {
    if (subdomain && this.searchTagsService.useOriginalCatalog !== setOriginal) {
      this.searchTagsService.changeCatalog(setOriginal);
      this.searchTagsService.clearTags();
      this.sendSearch();
    }

    if (!subdomain) {
      if (agreementCompany) {
        this.agreements.forEach((agr) => {
          if (
            agreementCompany.hasOwnProperty('check') &&
            agreementCompany.check &&
            agreementCompany.groups.join() !== agr.groups.join()
          ) {
            agr.check = false;
          }
        });
      }
      if (!agreementCompany?.check) {
        this.searchTagsService.useOriginalCatalog = false;
      }
      this.searchTagsService.displayCatalog(agreementCompany?.groups?.join() || null);
      this.sendSearch();
    }
  }

  public removeLyricsOption() {
    this.displayLyrics = false;
    let params = clone(this.router.routerState.snapshot.root.queryParams);
    delete params.lyrics;
    const url = this.router.createUrlTree([], { queryParams: params }).toString();
    this.playerService.showLyrics.next(false);
    this.playerService.showExtendedSearchSidebar.next(false);
    this.currentUrl = this.currentUrl.replace('&lyrics=open', '').replace('?lyrics=open', '');
    this.router.navigateByUrl(url);
  }

  public changeSortType() {
    this.sortType = this.sortType === 'label' ? 'totalSongs' : 'label';
    if (this.expandedTag && this.expandedTag.tags) {
      this.expandedTag.tags = this.sortTags(this.expandedTag.tags);
    } else {
      this.activeTab.tags = this.sortTags(this.activeTab.tags);
    }
  }

  public sortTags(tags) {
    const reverse = this.sortType !== 'label';
    if (reverse) {
      return sortBy(tags, [(tag) => tag[this.sortType]]).reverse();
    } else {
      return sortBy(tags, [(tag) => tag[this.sortType]]);
    }
  }

  public getAgreement() {
    this.termsModal.showModal({ subdomain: this.subdomain, agreement: this.agreement });
  }

  public getSlugOpenFullScreen(slug: string) {
    return this.openFullScreenSlugs.find((item) => item.slug === slug);
  }

  public validateTab(tab) {
    if (this.isArtistList) {
      const tabsToShow = ['genres', 'advanced-search', 'references'];
      return tabsToShow.includes(tab.slug);
    }
    if (tab.slug === 'budget') {
      return this.commonService.isAllowed([], ['UpdateTransaction:RestrictedLicenseRequester']);
    }

    return true;
  }

  private getEditorialPlaylistCount() {
    const params = this.playlistService.getParams(1, true, this.loggedUser);
    const sessionSettings = this.authenticationService.getUserSessionSettings();
    if (sessionSettings?.ignoreTags) {
      params.tags = '';
    }
    this.searchService.getSuggestions(params).subscribe({
      next: (res) => {
        this.editorialPlaylistTotal = res?.data?.playlists?.paging?.totalItems || 0;
      },
      error: (err) => {
        console.error('Error to get playlistsStereoSpacesService >>>> ', err);
        this.editorialPlaylistTotal = 0;
        this.trackingService.track('Error getting suggestions', {
          error: err,
          params: params,
        });
        Sentry.captureException(
          new Error(
            `There was an error getting suggestions. API error: status ${err.status} - ${
              err?.error?.message || err.message
            }`,
          ),
        );
      },
    });
  }

  public changePlaylistView() {
    this.trackingService.track(
      'Filters Tab Selected',
      {
        tab: 'Playlists',
        version: 1.0,
      },
      {
        event_action: 'Search bar tab selected',
        event_type: 'Search Bar Click',
        event_value: 'Playlists',
      },
    );
    this.showPlaylistMenu = !this.showPlaylistMenu;
    this.playerService.showPlaylistMenu.next(this.showPlaylistMenu);
    if (this.showPlaylistMenu) {
      this.closeTab();
    }
    this.changeDetectorRef.detectChanges();
  }

  editPlaylist(playlistData: any) {
    this.playlistModal.playlistDetails = playlistData;
    setTimeout(() => {
      this.playlistModal.showModal();
    });
  }

  playlistSelected($event) {
    const baseUrl = this.currentUrl.split('?');
    if (baseUrl[0].endsWith('playlist') || baseUrl[0].endsWith('edit-playlist')) {
      const route = this.router.url.includes('/edit-playlist') ? 'edit-playlist' : 'playlist';
      this.router.navigateByUrl(`/music-sync-licensing/${route}/${$event}?${baseUrl[1] || ''}`, {
        replaceUrl: true,
      });
    }
  }

  goToSearch() {
    this.trackingService.track(
      'Footer navigation',
      {
        name: 'search',
        route: '/search',
      },
      {
        event_action: 'Footer clicked',
        event_type: 'Footer Click',
        event_value: 'search',
      },
    );
    if (this.router.url === '/search' || this.router.url.includes('/music-sync-licensing')) {
      this.searchOpen = true;
    } else {
      this.router.navigate(['/search']);
    }
  }

  public openSearchSection() {
    this.playlistService.playlistMenuCollapsed.next({
      isCollapsed: false,
      searchSectionAction: true,
    });
    this.trackingService.track(
      'Search section opened with lens icon from edit-playlists',
      {},
      {
        event_action: 'Search section opened with lens icon from edit-playlists',
        event_type: 'Button Click',
      },
    );
  }

  public hideSearchSection() {
    this.collapsedSearchSidebar = true;
    this.playlistService.playlistMenuCollapsed.next({
      isCollapsed: true,
      searchSectionAction: true,
    });
    this.trackingService.track(
      'Search section closed with arrow-left icon',
      {},
      {
        event_action: 'Search section closed with arrow-left icon',
        event_type: 'Button Click',
      },
    );
  }

  public isExpandAndSmallSize() {
    return (
      this.playerService.isSmallSize ||
      this.expandedTag.hover ||
      this.expandedTag.selected ||
      this.expandedTag.selectedNegative
    );
  }

  public getUploadToken() {
    this.fileService
      .uploadToken({ moduleName: 'external', resourceType: 'Reference' })
      .subscribe((res) => {
        this.uploadToken = res.data.uploadToken;
      });
  }

  public updateTags(tags) {
    this.htmlTags = cloneDeep(tags);
  }

  ngOnDestroy() {
    this.playerService.isProcessingReference.next('none');
    this.searchTagsService.settingsList = cloneDeep(this.settingsListConfig);
    this.toastr.clear();
    this.newTrack$.unsubscribe();
    this.agreementUpdate$.unsubscribe();
    this.budgetTagsRemoved$.unsubscribe();
    this.searchTags$.unsubscribe();
    this.tagRemoved$.unsubscribe();
    this.allTagsRemoved$.unsubscribe();
    this.scrolled$.unsubscribe();
    this.showPlaylistMenuContainer$.unsubscribe();
    this.accessData$.unsubscribe();
    this.updateSelectedTags$.unsubscribe();
    this.showLyrics$.unsubscribe();
    this.showSettings$.unsubscribe();
    this.genericTagUpdated$.unsubscribe();
    this.playlistNumberUpdate$.unsubscribe();
    this.showPlaylistMenu$?.unsubscribe();
    this.changeDetectorRef.detach();
    this.updatePlaylistMenuCollapse$?.unsubscribe();
    this.$showPlaylistSettings.unsubscribe();
    this.suggestionSongService.showPlaylistSettings.next(false);
  }
}

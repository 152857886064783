<div class="extended-categories-scroll">
  <h6 *ngIf="!isSearching" style="font-weight: 600; color: var(--primary)" class="pl10">
    Playlist Suggestions
  </h6>
  <div class="col-sm-12 pl10 pr10 mb20" *ngIf="commonService.isAllowed(['ListTiers'])">
    <button class="sm-btn primary-btn button-mode" (click)="showSysAdmin = !showSysAdmin">
      {{ showSysAdmin ? 'Basic' : 'Advanced' }}
      <span class="material-icons-outlined"> wifi_protected_setup </span>
    </button>
  </div>
  <div class="col-sm-12 pl10 pr10" style="display: grid; grid-template-columns: 1fr 1fr">
    <div class="ml0 mb10 session-options" *ngIf="isSearching">
      <p class="color-primary mb0 mr10">Autoplay</p>
      <ui-switch
        switchColor="#fcfcfc"
        size="small"
        [(ngModel)]="playerAutoplay"
        (change)="setSettingUser($event, 'playerWidgetConfig')"
        color="#2ececb"
        switchColor="#d9f1f3"
      ></ui-switch>
    </div>
    <div
      *ngIf="commonService.isAllowed(['ListTiers']) && isSearching"
      class="ml0 mb10 session-options"
    >
      <p class="color-primary mb0 mr10">Show Tier</p>
      <ui-switch
        switchColor="#fcfcfc"
        size="small"
        [(ngModel)]="playerTierColumn"
        (change)="setSettingUser($event, 'playerListConfig')"
        color="#2ececb"
        switchColor="#d9f1f3"
      ></ui-switch>
    </div>

    <div
      class="ml0 mb10 session-options"
      *ngIf="commonService.isAllowed(['ListTiers']) && isSearching"
    >
      <p class="color-primary mb0 mr10">Scores</p>
      <ui-switch
        switchColor="#fcfcfc"
        size="small"
        [(ngModel)]="searchScore"
        (change)="optionWithAction($event, 'searchScore')"
        color="#2ececb"
        switchColor="#d9f1f3"
      ></ui-switch>
    </div>
    <div
      *ngIf="commonService.isAllowed(['ListTiers']) && isSearching"
      class="ml0 mb10 session-options"
    >
      <p class="color-primary mb0 mr10">Tag Report</p>
      <ui-switch
        switchColor="#fcfcfc"
        size="small"
        [(ngModel)]="tagReport"
        (change)="optionWithAction($event, 'tagReport')"
        color="#2ececb"
        switchColor="#d9f1f3"
      ></ui-switch>
    </div>
  </div>

  <ng-container *ngFor="let settingSet of settingList">
    <ng-container *ngIf="settingSet.category !== 'advancedSettings' && showCategory(settingSet)">
      <div class="col-sm-12 mt10 pl10 pr10">
        <p class="color-dark">{{ settingSet.category }}</p>
      </div>
      <ng-container *ngFor="let setting of settingSet.items">
        <div
          class="col-md-12 pb10 pl10 pr10"
          *ngIf="setting.slug !== 'minMatch' && showSysAdminOptions(setting)"
        >
          <label *ngIf="showSysAdmin">{{ setting.label }}</label>
          <div *ngIf="setting.type === 'select'" style="color: #000">
            <select
              [(ngModel)]="setting.value"
              class="form-control"
              (change)="multipleInputs(settingSet, setting)"
            >
              <option *ngFor="let option of setting.options" value="{{ option.value }}">
                {{ option.label || option.value }}
              </option>
            </select>
          </div>
          <div class="custom-slider mb10" *ngIf="isBrowser && setting.type !== 'select'">
            <ngx-slider
              (userChangeEnd)="sendSearch()"
              [(ngModel)]="setting.value"
              [options]="setting.options"
            ></ngx-slider>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="settingSet.category === 'Random' && settingSet.items.length > 1"
        class="col-md-12 pb10 pl10 pr10"
      >
        <p class="color-dark">{{ settingSet.items[2].label }}</p>
        <div class="custom-slider mb10" *ngIf="isBrowser">
          <ngx-slider
            (userChangeEnd)="onTagMatchSliderChange()"
            [(ngModel)]="settingSet.items[2].value"
            [options]="settingSet.items[2].options"
          ></ngx-slider>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { clone } from 'lodash-es';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { SearchTagsService } from 'src/app/angular/shared/services/search-tags.service';
import { PlayerService } from 'src/app/angular/shared/services/player.service';

@Component({
  selector: 'app-song-type-search',
  templateUrl: './song-type-search.component.html',
  styleUrls: ['./song-type-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SongTypeSearchComponent {
  @Output() public sendSearchEvent = new EventEmitter<void>();
  @Output() public updateTags = new EventEmitter<any>();
  @Input() public htmlTags: any;

  constructor(
    public commonService: CommonService,
    public searchTagsService: SearchTagsService,
    public playerService: PlayerService,
  ) {}

  public removeVersionTag() {
    const versionTag = this.searchTagsService.selectedTags.find((t: any) => t.category === 'type');
    this.searchTagsService.removeTag(versionTag);
    this.sendSearchEvent.emit();
  }

  public setSearchVersion(versionTag) {
    const oldVersionTag: any = this.searchTagsService.selectedTags.find(
      (t: any) => t.category === 'type',
    );
    if (oldVersionTag && oldVersionTag.slug === versionTag.slug) {
      this.searchTagsService.removeTag(versionTag);
    } else {
      this.searchTagsService.addVersionTag(versionTag);
    }
    this.sendSearchEvent.emit();
  }

  public setSingleTag(tag, isNegative = false) {
    tag.selectedNegative = !!isNegative;
    tag.selected = !!!isNegative;
    tag.negative = isNegative;
    const currentTag: any = this.searchTagsService.selectedTags.find(
      (t: any) => t.category === tag.category,
    );
    if (currentTag) {
      this.searchTagsService.removeTag(tag);
      if (this.htmlTags[tag.category]) {
        this.htmlTags[tag.category].selected = false;
        this.htmlTags[tag.category].negative = false;
        this.htmlTags[tag.category].hover = false;
      }
    }
    if (!currentTag || currentTag?.negative !== isNegative) {
      this.searchTagsService.addTag(clone(tag));
      if (this.htmlTags[tag.category]) {
        this.htmlTags[tag.category].selected = !isNegative;
        this.htmlTags[tag.category].negative = isNegative;
        this.htmlTags[tag.category].hover = false;
      }
    }
    this.updateTags.emit(this.htmlTags);
    console.log('set tags', this.htmlTags);

    this.sendSearchEvent.emit();
  }
}

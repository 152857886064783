import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalsModule } from '../modals/modals.module';
import { SharedModule } from '../../shared/shared.module';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FullPageLoaderComponent } from './components/full-page-loader/full-page-loader.component';
import { SearchSidebarComponent } from './components/search-sidebar/search-sidebar.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { PlaylistsSharedModule } from '../../layout/components/playlist-shared/playlist-shared.module';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SettingsSidebarComponent } from './components/search-sidebar/settings-sidebar/settings-sidebar.component';
import { FullScreenSearchComponent } from './components/search-sidebar/full-screen-search/full-screen-search.component';
import { SongTypeSearchComponent } from './components/search-sidebar/song-type-search/song-type-search.component';
import { AccountRequestModalComponent } from '../modals/components/account-request-modal/account-request-modal.component';
import { FileUploadComponent } from '../../shared/components/file-upload/file-upload.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';

@NgModule({
  declarations: [
    SidenavComponent,
    FullPageLoaderComponent,
    SearchSidebarComponent,
    AdvancedSearchComponent,
    SettingsSidebarComponent,
    FullScreenSearchComponent,
    SongTypeSearchComponent,
  ],
  imports: [
    CommonModule,
    ModalsModule,
    SharedModule,
    RouterModule,
    UiSwitchModule,
    RecaptchaModule,
    PlaylistsSharedModule,
    NgxSliderModule,
    AccountRequestModalComponent,
    FileUploadComponent,
    TypeaheadModule,
    BsDatepickerModule.forRoot(),
    TooltipModule,
    AngularMultiSelectModule,
  ],
  exports: [
    SidenavComponent,
    FullPageLoaderComponent,
    SearchSidebarComponent,
    FullScreenSearchComponent,
  ],
})
export class SharedLayoutModule {}

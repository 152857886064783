<div id="captchaBottom" style="margin: 0 auto; display: table">
  <re-captcha
    #captchaRef="reCaptcha"
    size="invisible"
    name="recaptcha"
    badge="bottom"
    siteKey="{{ environment.recaptchaInvisibleSiteKey }}"
    (error)="onRecaptchaError($event)"
  ></re-captcha>
</div>
<div class="row section-1">
  <div class="col-md-12">
    <button type="button" class="close mt20 mr20" (click)="toggleFullScreenSearch(false)">
      <span class="sr-only">Close</span><span class="ss-icon-close" style="font-size: 50px"></span>
    </button>
  </div>
</div>
<!-- Results list in typing search -->
<div
  *ngIf="
    searchTagsService.selectedTags.length > 0 &&
    suggestions &&
    !suggestionsLoading &&
    atLeastOnePositiveTag
  "
  class="tags-typing-search"
>
  <p>
    <i class="fa fa-info-circle" aria-hidden="true"></i>
    These results match your current search AND your selected tags:
  </p>
  <div class="display-i">
    <ng-container *ngFor="let selectedTag of searchTagsService.selectedTags; trackBy: trackByIndex">
      <div
        class="selected-tag"
        [ngClass]="{ 'negative-tag': selectedTag.negative, mt10: playerService.isSmallSize }"
        [ngStyle]="{ 'margin-right': playerService.isSmallSize ? '10px' : '3px' }"
        (click)="onFullScreenSearchKeyUp({}); toggleTagType(selectedTag); sendSearch()"
        (mouseenter)="selectedTag.selectedHover = true"
        (mouseleave)="selectedTag.selectedHover = false"
        *ngIf="!selectedTag.negative"
      >
        <span [ngClass]="{ 'pl5 pr5': playerService.isSmallSize }">
          {{ selectedTag.label }}
        </span>
        <div
          *ngIf="selectedTag.selectedHover || playerService.isSmallSize"
          class="remove-btn"
          (click)="$event.stopPropagation(); removeTag(selectedTag); onFullScreenSearchKeyUp({})"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="row section-2 ml0 mr0">
  <div id="searchSidebarWrapper" class="col-md-12">
    <input
      *ngIf="!uploadFile"
      id="searchSidebarInput"
      data-hj-allow
      autofocus
      type="text"
      autocomplete="off"
      [(ngModel)]="typedSearch"
      (ngModelChange)="onTypedSearchChange()"
      (keyup)="onFullScreenSearchKeyUp($event)"
      [placeholder]="searchPlaceholder"
    />
  </div>
  <div class="col-sm-12 d-flex justify-content-center">
    <div class="file-upload-container-search col-sm-12 col-md-8 col-lg-5" *ngIf="uploadFile">
      <app-file-upload
        [fileType]="'.mp3'"
        [fileText]="'MP3'"
        [uploadFolder]="false"
        [uploadToken]="uploadToken"
        [privateFile]="true"
        [showProgressBar]="true"
        (tracks)="trackUpdate($event)"
        (error)="uploadError($event)"
        #fileUploadComponent
      >
      </app-file-upload>
    </div>
  </div>
</div>
<div
  class="row main-youtube-reference"
  *ngIf="playerService.isYoutubeReference && !suggestions"
  [@inOutAnimation]
>
  <div
    id="youtube-explanation"
    [ngClass]="{ 'hidden-opacity': wavesReady }"
    class="d-flex flex-wrap ml0 mr0 mt20 pl20 pr20"
  >
    <div class="col-xs-12 col-sm-3 d-flex align-items-center justify-content-center mb20">
      <div class="d-flex flex-column align-items-center">
        <img src="./assets/images/youtube/full-track.svg" alt="full track" />
        <h6 class="color-dark">We are going to listen to your reference</h6>
      </div>
    </div>
    <div
      class="col-xs-12 col-sm-3 d-flex flex-column align-items-center justify-content-center mb20"
    >
      <div class="mb20">
        <img src="./assets/images/youtube/intro.svg" alt="full track" />
        <label class="color-dark ml10" style="width: 50px">Intro</label>
      </div>
      <div class="mb20">
        <img src="./assets/images/youtube/verse.svg" alt="full track" />
        <label class="color-dark ml10" style="width: 50px">Verse</label>
      </div>
      <div class="mb20">
        <img src="./assets/images/youtube/chorus.svg" alt="full track" />
        <label class="color-dark ml10" style="width: 50px">Chorus</label>
      </div>
      <div class="mb20">
        <img src="./assets/images/youtube/outro.svg" alt="full track" />
        <label class="color-dark ml10" style="width: 50px">Outro</label>
      </div>
      <h6 class="color-dark">Analyze each segment of your track</h6>
    </div>
    <div
      class="col-xs-12 col-sm-3 d-flex flex-column align-items-center justify-content-center mb20"
    >
      <div class="mb20">
        <label class="color-dark ml10" style="width: 20px">1.</label>
        <img src="./assets/images/youtube/res1.svg" alt="full track" />
      </div>
      <div class="mb20">
        <label class="color-dark ml10" style="width: 20px">2.</label>
        <img src="./assets/images/youtube/res2.svg" alt="full track" />
      </div>
      <div class="mb20">
        <label class="color-dark ml10" style="width: 20px">3.</label>
        <img src="./assets/images/youtube/res3.svg" alt="full track" />
      </div>
      <h6 class="color-dark">And show you the results highlighted where we found the best match</h6>
    </div>
    <div
      class="col-xs-12 col-sm-3 d-flex flex-column align-items-center justify-content-center mb20"
    >
      <h6 class="color-dark">
        For better results you can select the segment of your track your are more interested in
      </h6>
      <div class="mb20">
        <img src="./assets/images/youtube/select-segment.svg" alt="full track" />
      </div>
      <h6 class="color-dark">And add any tag to filter the results</h6>
      <img src="./assets/images/youtube/sample-tags.png" alt="full track" />
    </div>
  </div>
  <div
    *ngIf="externalAudio !== '' && isProcessingReference !== 'none'"
    id="download-song"
    class="col-lg-6 col-sm-10 col-xs-10"
    [ngClass]="{ 'hidden-opacity': !wavesReady }"
  >
    <h6 *ngIf="showSegment" class="color-dark mb30">
      Please choose the specific segment of your reference that captures your interest
    </h6>
    <app-wave-surfer-generic
      [audioUrl]="externalAudio"
      [showReferenceText]="showSegment"
      [containerId]="'download-song'"
      [showSegment]="showSegment"
      [initSegment]="segment"
      [segmentsGroups]="segmentGroupsValues"
      (thisReady)="onReadyWaves()"
      (changeSegment)="onChangeSegment($event)"
    ></app-wave-surfer-generic>
  </div>
  <div class="col-xs-12">
    <div
      *ngIf="
        isProcessingReference === 'processing' || (isProcessingReference === 'end' && !wavesReady)
      "
      class="row ml0 mr0"
    >
      <div
        class="progress col-xs-offset-1 col-xs-10 col-lg-offset-3 col-lg-6 pl0 pr0 mt20"
        style="height: 30px"
      >
        <div
          style="font-weight: 600; font-size: 14px"
          class="progress-bar progress-bar-striped active d-flex justify-content-center align-items-center"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          [ngStyle]="{
            width: (percentageReference > 0 ? percentageReference : '100') + '%'
          }"
        >
          <span class="sr-only">{{ percentageReference }}% Complete</span>
          {{ percentageReference > 0 ? percentageReference + '%' : 'Loading...' }}
        </div>
      </div>
    </div>
    <div class="pl10 pr10">
      <div
        *ngIf="
          isProcessingReference === 'processing' || (isProcessingReference === 'end' && !wavesReady)
        "
        class="d-flex flex-column align-items-center mb20"
      >
        <p class="mb10 secondary-color">
          We are processing your reference! Please wait a couple of seconds!
        </p>
      </div>
      <div
        *ngIf="isProcessingReference === 'end' && wavesReady"
        class="d-flex flex-column align-items-center mb20"
      >
        <button class="btn primary-btn primary-light" (click)="listenToSimilarSongYT()">
          Listen to Similar Songs
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="suggestionsLoading" [@inOutAnimation]>
  <div class="mt50" align="center">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<div *ngIf="noResults && !playerService.isYoutubeReference" class="no-results-container">
  <i class="fa fa-search" aria-hidden="true" style="font-size: 10rem"></i>
  <h1>Hey, we couldn't find exactly what you're looking for!</h1>
  <p class="mt20">
    You could search for
    <ng-container *ngFor="let exm of suggestionsExamples; let suggestionIndex = index">
      <a (click)="typedSearch = exm.slug; getSuggestions(exm.slug)">
        {{ exm.label }}
      </a>
      {{ suggestionIndex < suggestionsExamples.length - 1 ? ', ' : '' }}
    </ng-container>
  </p>
</div>
<div
  class="row flex-row-search-sidebar flex-nowrap pt30 section-3"
  *ngIf="suggestions && !suggestionsLoading"
  [@inOutAnimation]
>
  <ng-container *ngFor="let option of suggestions">
    <ng-container *ngIf="option.aggregations && option.aggregations.length > 0">
      <div id="tags-wrapper" class="flex-col mb20 tag-section">
        <p class="discover-search-title mb5">
          {{ option.label }} ({{ option.aggregations.length }})
        </p>
        <hr class="mt0" style="border-color: #cacaca" />
        <div
          *ngFor="let item of option.aggregations; let tagWrapperIndex = index"
          (click)="addTagFromAggregations(item)"
          (mouseenter)="item.hover = true"
          (mouseleave)="item.hover = false"
        >
          <div
            class="center-flex"
            *ngIf="tagWrapperIndex + 1 < heightLimits['general']['value'] / tagsHeights['general']"
          >
            <div class="tag-label" [ngClass]="{ 'tag-label-short': item.hover }">
              <span class="discover-search-item mb0">
                <em class="search-item-accent"> {{ item.label }} ({{ item.count }} songs) </em>
              </span>
            </div>
            <ng-container *ngIf="playerService.isSmallSize || item.hover">
              <div
                class="selection-tag-opt"
                (click)="addTagFromAggregations(item); $event.stopPropagation()"
              >
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div
                class="selection-tag-opt"
                (click)="addTagFromAggregations(item, true); $event.stopPropagation()"
              >
                <i class="fa fa-ban" aria-hidden="true"></i>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="option.items.length > 0 && option.id !== 'lyric-strict'">
      <div id="tags-wrapper" class="flex-col mb20 tag-section">
        <p class="discover-search-title mb5">
          {{ option.label }} ({{ option.paging.totalItems }})
          <ng-container *ngIf="option.id === 'songs' || option.id === 'playlists'">
            <button
              class="btn primary-btn sm-btn pull-right"
              (click)="$event.stopPropagation(); sendSearchWithQueryTag(option)"
            >
              <p style="font-size: 10px; display: inline">
                {{ option.id === 'songs' ? 'Play' : 'Show' }} All&nbsp;
              </p>
              <i
                class="fa fa-play-circle"
                aria-hidden="true"
                style="font-size: 12px; top: 1px; position: relative"
              ></i>
            </button>
          </ng-container>
        </p>
        <hr class="mt0" style="border-color: #cacaca" />
        <div *ngIf="option.id === 'lyric' || option.id === 'lyric-strict'">
          <ng-container *ngFor="let lyricData of option.lyricData; let lyricWrapperIndex = index">
            <div
              (click)="addQueryTag(lyricData)"
              [ngClass]="{ mt40: lyricWrapperIndex !== 0 }"
              class="lyrics-wrapper"
            >
              <div class="color-dark" style="display: flex">
                <i class="fa fa-play-circle add-tag center-v-flex mr10" aria-hidden="true"></i>
                <p class="mb0">
                  Play all <span class="fw500">{{ lyricData.paging.totalItems }}</span> songs
                  <br />
                  with {{ lyricData.id === 'lyric-strict' ? 'exactly ' : '' }}
                  <span class="fw500">{{ typedSearch }}</span>
                  {{ lyricData.id !== 'lyric-strict' ? 'and variations' : '' }}
                </p>
              </div>
              <ng-container *ngFor="let item of lyricData.items; let lyricIdx = index">
                <ng-container *ngIf="lyricIdx < 2">
                  <ng-container
                    *ngFor="let lyricFragment of item.highlights; let highlightIdx = index"
                  >
                    <p class="mb0 pl15 lyrics-quotes">
                      <span style="top: 2px" *ngIf="highlightIdx === 0">“</span>
                      <span
                        [ngClass]="{ ml7: highlightIdx === item.highlights.length - 1 }"
                        class="high-light-discover high-light-elm search-text-display"
                        [innerHTML]="lyricFragment"
                        >.
                      </span>
                      <span *ngIf="highlightIdx === 0">...</span>
                      <span
                        style="top: 2px; margin-left: 2px"
                        *ngIf="highlightIdx === item.highlights.length - 1"
                        >”</span
                      >
                    </p>
                    <p *ngIf="highlightIdx === item.highlights.length - 1" class="lyrics-info">
                      {{ item.label }} by {{ item.artistNames }}
                    </p>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div *ngIf="option.id === 'playlists'">
          <ng-container
            *ngFor="let selectedTag of searchTagsService.selectedTags; trackBy: trackByIndex"
          >
            <div
              class="selected-tag mb10"
              [ngClass]="{
                'negative-tag': selectedTag.negative,
                mt10: playerService.isSmallSize
              }"
              [ngStyle]="{ 'margin-right': playerService.isSmallSize ? '10px' : '3px' }"
              (click)="onFullScreenSearchKeyUp({}); toggleTagType(selectedTag); sendSearch()"
              (mouseenter)="selectedTag.selectedHover = true"
              (mouseleave)="selectedTag.selectedHover = false"
              *ngIf="selectedTag.negative"
            >
              <span [ngClass]="{ 'pl5 pr5': playerService.isSmallSize }">
                {{ selectedTag.label }}
              </span>
              <div
                *ngIf="selectedTag.selectedHover || playerService.isSmallSize"
                class="remove-btn"
                (click)="
                  $event.stopPropagation(); removeTag(selectedTag); onFullScreenSearchKeyUp({})
                "
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          *ngFor="let item of option.items; let tagWrapperIndex = index"
          (click)="addTagFromSuggestions(item)"
        >
          <div
            *ngIf="
              option.id !== 'songs' &&
              option.id !== 'lyric' &&
              option.id !== 'playlists' &&
              tagWrapperIndex + 1 < heightLimits['general']['value'] / tagsHeights['general']
            "
            class="center-flex"
            (mouseenter)="item.hover = true"
            (mouseleave)="item.hover = false"
          >
            <div class="tag-label" [ngClass]="{ 'tag-label-short': item.hover }">
              <span class="discover-search-item mr10" [innerHTML]="item.highlight"></span>
            </div>
            <ng-container
              *ngIf="(playerService.isSmallSize || item.hover) && option.id !== 'artists'"
            >
              <div
                class="selection-tag-opt"
                (click)="addTagFromSuggestions(item); $event.stopPropagation()"
              >
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div
                class="selection-tag-opt"
                (click)="addTagFromSuggestions(item, true); $event.stopPropagation()"
              >
                <i class="fa fa-ban" aria-hidden="true"></i>
              </div>
            </ng-container>
            <br />
          </div>
          <div
            *ngIf="
              option.id === 'songs' &&
              tagWrapperIndex + 1 < heightLimits['songs']['value'] / tagsHeights['songs']
            "
          >
            <p class="discover-search-item mb0" [innerHTML]="item.highlight"></p>
            <p class="discover-song-artist">{{ item.artistNames }}</p>
          </div>
          <div
            *ngIf="
              option.id === 'playlists' &&
              tagWrapperIndex + 1 < heightLimits['playlists']['value'] / tagsHeights['playlists']
            "
            class="playlist-typing-container"
          >
            <div class="pl-img-container">
              <img
                *ngIf="item?.thumbUrl"
                [src]="item?.thumbUrl"
                alt="playlist"
                class="mb10 background-card pl-thumbnail"
                (error)="handleMissingImage($event)"
              />
              <span
                class="material-icons absolute-center pl-icon mt5"
                [attr.data-icon]="'playlist_play'"
              >
              </span>
            </div>
            <div class="playlist-typing-info">
              <p
                class="discover-search-item mb0"
                [innerHTML]="item?.highlight?.name || item?.label"
              ></p>
              <p *ngIf="item?.highlight?.tags" class="medium-discover-search-item">
                <span *ngFor="let tag of item?.highlight?.tags; let i = index">
                  <span
                    [innerHTML]="'#' + tag + (i < (item?.highlight?.tags).length - 1 ? ' ' : '')"
                  ></span>
                </span>
              </p>
              <p
                *ngIf="!item?.highlight?.tags && item?.highlight?.description"
                class="medium-discover-search-item"
                [innerHTML]="item?.highlight?.description"
              ></p>
              <p
                *ngIf="
                  !subdomain || searchTagsService.useOriginalCatalog || tenantId !== 'sostereo'
                "
                class="pl-tracks"
              >
                {{ item.totalPublicSongs }} tracks
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Renderer2,
  ViewChild,
  Inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import {
  ArtistService,
  AuthenticationService,
  PlaylistsStereoSpacesService,
  TransactionsService,
} from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { intersection, isEmpty, isEqual, remove } from 'lodash-es';
import { sostereoSidenav } from '../../configs/sidenav.config';
import { Location, isPlatformBrowser } from '@angular/common';
import { transition, trigger, useAnimation } from '@angular/animations';
import { BehaviorSubject, Subject, Subscription, debounceTime } from 'rxjs';
import { inOutAnimation } from '../../../../shared/animations/in-out.animation';
import { PlayerService } from '../../../../shared/services/player.service';
import { PlaylistService } from '../../../../shared/services/playlist.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
})
export class SidenavComponent implements OnInit, OnChanges, OnDestroy {
  public loggedUser: any;
  public company: any;
  public sidenavOptions: any[];
  public showBlocker: boolean;
  public currentRoute = this.router.url;
  public loggedAs = false;
  public subdomainAccess = false;
  public previewUser: any;
  public subdomain: any = null;
  public tenantId;
  public scrolled = false;
  public licenseDraft = null;
  public spacesLogo;
  public currentUrl = this.router.url;
  private playlistsCount: number;
  public trialSection = 'Trial';
  private firstPlId = '';
  private completeProfilePerc: string;
  private getPlaylistsCount: BehaviorSubject<number> = this.commonService.playlistsCount;
  private scrolled$: Subscription = new Subscription();
  private getPlaylistsCount$: Subscription;
  private completeProfilePerc$: Subscription;
  public currentUser = this.authenticationService.getCurrentUser();
  public isAdmin = this.commonService.isAllowed(['*', 'ListTiers']);
  public isArtistOrLabel =
    this.commonService.isAllowed(['GetArtist'], ['ListArtists', 'UpdateTier']) ||
    this.commonService.isAllowed(['ListArtists'], ['UpdateTier']);

  private toastRef;
  private helpCenterRole;
  private goToSubject$ = new Subject<any>();
  public isMobile = false;
  public isTablet = false;

  @Input() scopes: any[];
  @Input() containerHeight: string;
  @ViewChild('briefSubmissionModalComponent', { static: true }) briefSubmissionModalComponent;
  @ViewChild('sessionExpiredModal', { static: true }) sessionExpiredModal;
  isBrowser: boolean;
  private previousRoute: string = '/';

  constructor(
    private trackingService: TrackingService,
    private authenticationService: AuthenticationService,
    public playerService: PlayerService,
    private playlistService: PlaylistsStereoSpacesService,
    private artistService: ArtistService,
    private commonService: CommonService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private transactionService: TransactionsService,
    public deviceDetectorService: DeviceDetectorService,
    public commonPlaylistService: PlaylistService,
    private toastr: ToastrService,
    @Inject('environment') public environment,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.routerSubscribe();
    this.tenantId = this.commonService.tenantId;
    this.subdomain = this.commonService.getSubdomain();
    this.spacesLogo = `https://img-sp.stereospaces.com/${this.subdomain}-images-${
      this.environment.production ? 'production' : 'stg'
    }/companies/${this.subdomain}.svg`;
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.currentRoute = evt.url;
        this.router.navigated = false;
      }
    });
    const subdomain = this.commonService.getSubdomain();
    this.commonService.getCompany(subdomain, (company) => {
      if (company) {
        this.company = company;
        this.tenantId = this.commonService.tenantId;
        this.configSideBar();
      }
    });
    this.showBlocker = false;
    this.authenticationService.accessData.subscribe((user) => {
      this.setUpLoggedUser(user);
      this.isAdmin = this.commonService.isAllowed(['*', 'ListTiers']);
    });

    this.loggedAs = false;
    if (
      localStorage.getItem('SOSTEREO.userToken') &&
      localStorage.getItem('SOSTEREO.originalUser')
    ) {
      this.previewUser = JSON.parse(localStorage.getItem('SOSTEREO.currentUser'));
      this.loggedAs = true;
      this.containerHeight = '90%';
    }
    if (
      this.route.snapshot.queryParams.accessAccount ||
      localStorage.getItem('SOSTEREO.subdomainAccess')
    ) {
      this.subdomainAccess = true;
      if (localStorage.getItem('SOSTEREO.currentUser')) {
        this.previewUser = JSON.parse(localStorage.getItem('SOSTEREO.currentUser'));
      }
      this.loggedAs = true;
      this.containerHeight = '90%';
      if (!localStorage.getItem('SOSTEREO.subdomainAccess')) {
        localStorage.setItem('SOSTEREO.subdomainAccess', 'true');
      }
    }
    this.authenticationService.accessData.subscribe((user) => {
      this.loggedUser = user;
      if (this.subdomainAccess && this.previewUser === undefined) {
        this.previewUser = JSON.parse(localStorage.getItem('SOSTEREO.currentUser'));
        return;
      }
      if (!this.loggedUser) {
        this.loggedAs = false;
        this.previewUser = null;
      }
      if (
        localStorage.getItem('SOSTEREO.userToken') &&
        localStorage.getItem('SOSTEREO.originalUser')
      ) {
        this.previewUser = JSON.parse(localStorage.getItem('SOSTEREO.currentUser'));
        this.loggedAs = true;
        this.containerHeight = '90%';
      }
    });

    this.scrolled$ = this.playerService.scrolled.subscribe((value) => {
      this.scrolled = value;
    });

    this.getLicense();

    this.transactionService.transactionDraftSubject.subscribe((transaction) => {
      const license: any = transaction;
      this.licenseDraft = license ? license.items.length : null;
    });
  }

  ngOnInit() {
    this.completeProfilePerc$ = this.commonService.completeProfilePerc.subscribe((perc) => {
      this.completeProfilePerc = `${perc}%`;
    });
    this.isPlaylistActive();
    this.getSelectedOption();
    this.goToSubject$.pipe(debounceTime(300)).subscribe((optionValue) => {
      this.goTo(optionValue);
    });
  }

  private routerSubscribe() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
        this.getSelectedOption();
      }
    });
  }

  private getSelectedOption() {
    this.sidenavOptions.forEach((s) => {
      s.selected = this.validateRoute(s);
    });
  }

  public isPlaylistActive() {
    const activeOption = this.sidenavOptions.find((op) => op.id === 'my-playlist');
    if (activeOption?.id === 'my-playlist' && this.router.url.includes(activeOption?.route)) {
      this.commonPlaylistService.playlistMenuCollapsed.next({ isCollapsed: true });
      this.playerService.showPlaylistMenu.next(true);
    }
  }

  ngOnChanges() {
    if (this.scopes) {
      this.setUpLoggedUser(this.authenticationService.getCurrentUser());
    }
  }

  configSideBar() {
    const companyItems: any[] =
      this.company?.theme?.sidebarMenu?.map((element) => ({
        id: element.icon,
        name: element.label,
        externalRoute: element.url,
        googleIcon: element.icon,
        allowed: ['public'],
        disallowed: [],
        hideForMobile: element.icon === 'home',
      })) || [];
    this.sidenavOptions = companyItems.concat(
      sostereoSidenav(
        this.environment.name,
        this.subdomain,
        this.loggedUser,
        this.isArtistOrLabel,
        this.helpCenterRole,
      ),
    );
    remove(
      this.sidenavOptions,
      (s) =>
        !s ||
        (s.disallowSubdomain &&
          this.tenantId === 'sostereo' &&
          (this.subdomain || !this.isBrowser)) ||
        (s.subdomainOnly && !this.subdomain) ||
        (s.hideHeader && this.company?.theme?.hideHeader),
    );
  }

  setUpLoggedUser(user) {
    this.loggedUser = user;
    this.subdomain = this.commonService.getSubdomain();
    if (user?.scopes) {
      this.helpCenterRole = this.trackingService.getUserRole(user.scopes)?.toLowerCase();
    }
    this.configSideBar();
    if (this.loggedUser) {
      if (
        this.isAllowed(
          ['ListPlaylists', 'ListMixtapes'],
          ['ListMusicSupervisors', 'ListMusicsupervisors'],
        )
      ) {
        this.commonService.getPlaylistsCount();
        this.getPlaylistsCount$ = this.getPlaylistsCount.subscribe((count) => {
          console.log(
            'Update playlist count >>> ',
            count,
            this.commonService.firstPlaylist.getValue(),
          );
          this.firstPlId = this.commonService.firstPlaylist.getValue();
          this.playlistsCount = count;
        });
      }
      if (
        this.commonService.isAllowed(
          ['GetArtist', 'UpdateArtist', 'ListArtists'],
          ['*', 'UpdateSearchSettings', 'GetAgreement'],
        ) &&
        this.sidenavOptions.find((s) => s.id === 'home')
      ) {
        this.sidenavOptions.find((s) => s.id === 'home').hide = true;
      }
      if (this.isAllowed(['GetArtist', 'UpdateArtist'], [])) {
        this.artistService.me().subscribe((res) => {
          this.completeProfilePerc = `${this.commonService.setCompleteProfilePerc(res.data)}%`;
        });
      }
      if (
        user?.scopes?.includes('RestrictedLicenseRequester') &&
        this.sidenavOptions.find((s) => s.id === 'sonic-analysis')
      ) {
        this.sidenavOptions.find((s) => s.id === 'sonic-analysis').hide = true;
      }
    }
  }

  isAllowed(allowedScopes, disallowedScopes) {
    if (allowedScopes.includes('public') && isEmpty(disallowedScopes)) {
      return true;
    }
    if (this.loggedUser?.scopes) {
      if (
        this.loggedUser.scopes.indexOf('UpdateRating') !== -1 &&
        this.loggedUser.scopes.indexOf('UpdateSongEditor') === -1 &&
        isEqual(allowedScopes, ['ListSongs'])
      ) {
        return false;
      }
      const intersectionDisallow = intersection(this.loggedUser.scopes, disallowedScopes);
      if (intersectionDisallow.length > 0) {
        return false;
      } else {
        const intersectionAllow = intersection(this.loggedUser.scopes, allowedScopes);
        return intersectionAllow.length > 0;
      }
    } else {
      return false;
    }
  }

  validateRoute(option) {
    if (!option.activeRoutes) {
      if (option.route === '/') {
        return this.router.url === option.route;
      }
      if (window.location.href.includes('draft')) {
        return option.route?.includes('draft');
      } else {
        if (this.router.url.includes('/analytics/transfers')) {
          return option.route === '/analytics';
        } else return window.location.href.includes(option.route);
      }
    }
    return option.activeRoutes.includes(this.router.url.split('?')[0]);
  }

  trackingRoute(option) {
    this.trackingService.track(
      'Navbar navigation',
      {
        name: option?.name,
        route: option?.route || option.externalRoute,
      },
      {
        event_action: 'Navbar clicked',
        event_type: 'Navbar Click',
        event_value: option.name,
      },
    );
  }

  routeShareParams = ['/discover', '/playlist', '/music-sync-licensing/search', '/edit-playlist'];

  getShareParams(option, currentRoute) {
    const newParams =
      this.routeShareParams.some((route) => currentRoute.includes(route)) &&
      this.routeShareParams.some((route) => option.route.includes(route))
        ? this.router.routerState.snapshot.root.queryParams
        : {};
    return {
      ...newParams,
      songFeaturedIn: null,
      name: null,
      songId: null,
      identity: null,
      versionId: null,
    };
  }

  goTo(option) {
    console.log('goTo sidenav', option);
    this.trackingRoute(option);
    if (option.externalRoute) {
      if (option.externalRoute.includes('blog/category/help')) {
        window.location.href = `${
          this.environment.appUrl === 'https://qa.sostereo.com'
            ? 'https://qa.sostereo.com'
            : this.environment.appUrl
        }/${option.externalRoute}`;
      } else {
        window.location.href = option.externalRoute;
      }
      return;
    }
    if (option.route) {
      this.playerService.showPlaylistMenu.next(false);
    }

    this.commonPlaylistService.playlistMenuCollapsed.next({
      isCollapsed: option.id === 'my-playlist',
    });

    if (option.modal) {
      this[option.modal].showModal();
    } else if (
      this.previousRoute.includes('/iam-dashboard') ||
      this.previousRoute.includes('/iam-companies')
    ) {
      window.location.href = option.route;
    } else if (option.queryParamsHandling === 'merge') {
      const params: any = this.getShareParams(option, this.previousRoute);
      const routeOptional =
        option.route === '/discover' && !!params.tags
          ? '/music-sync-licensing/search'
          : option.route;
      const route = [routeOptional];
      const newUrl = this.router.createUrlTree(route, { queryParams: params });
      if (option.route === '/music-sync-licensing/edit-playlist') {
        this.playerService.showPlaylistMenu.next(true);
      }
      if (newUrl.toString() !== this.previousRoute) {
        this.router.navigate(route, { queryParams: params });
      }
    }
  }

  onSidenavClick(optionValue: string) {
    this.previousRoute = this.router.url;
    this.goToSubject$.next(optionValue);
  }

  toggleSidenav() {
    const sidenavContainer = document.getElementById('sidenav');
    console.log(sidenavContainer.getAttribute('class'));
    if (
      sidenavContainer.getAttribute('class') !== null &&
      sidenavContainer.getAttribute('class').includes('toggled')
    ) {
      this.renderer.removeClass(sidenavContainer, 'toggled');
      this.showBlocker = false;
    } else {
      this.renderer.addClass(sidenavContainer, 'toggled');
      this.showBlocker = true;
    }
  }

  logOut() {
    if (this.isBrowser) {
      //this.trackingService.resetMixpanelSession();
      this.authenticationService.logout();
    }
  }

  @Input()
  set sessionExpired(sessionExpired: boolean) {
    console.log('prev value: ', sessionExpired);
    if (sessionExpired) {
      setTimeout(() => {
        this.sessionExpiredModal.showModal();
        document.getElementsByTagName('modal-container')[0].setAttribute('class', 'modal fade in');
      }, 1000);
    }
  }

  resetOriginalUser() {
    if (this.subdomainAccess) {
      this.subdomainAccess = false;
      localStorage.removeItem('SOSTEREO.subdomainAccess');
      this.logOut();
      window.location.href = `${this.environment.redirectAppUrl}/iam/list`;
    } else {
      setTimeout(() => {
        this.loggedAs = false;
      });
      const originalUserData = JSON.parse(localStorage.getItem('SOSTEREO.originalUser'));
      const originalSettings = JSON.parse(localStorage.getItem('SOSTEREO.originalSessionSettings'));
      if (originalSettings) {
        localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(originalSettings));
      } else {
        localStorage.removeItem('SOSTEREO.sessionSettings');
      }
      this.authenticationService.scopes = originalUserData.scopes;
      this.authenticationService.setCurrentUser(originalUserData, true);
      localStorage.removeItem('SOSTEREO.userToken');
      localStorage.removeItem('SOSTEREO.originalUser');
      localStorage.removeItem('SOSTEREO.originalSessionSettings');
      window.location.href = '/iam/list';
    }
  }

  ngOnDestroy() {
    this.scrolled$.unsubscribe();
    this.getPlaylistsCount$?.unsubscribe();
    this.completeProfilePerc$?.unsubscribe();
    this.goToSubject$.complete();
  }

  getLicense() {
    const license = this.transactionService.getTransactionDraft();
    this.licenseDraft = license ? license.items.length : null;
  }

  loginAndSignUp(navigateTo: boolean) {
    const url = window.location.pathname + window.location.search;
    const params = navigateTo
      ? { queryParams: { returnUrl: url, sU: navigateTo } }
      : { queryParams: { returnUrl: url } };
    this.router.navigate(['/login'], params);
  }

  public switchWorkspace(workspace) {
    this.toastRef = this.toastr.info(`Switching to ${workspace} workspace`, '', {
      tapToDismiss: true,
      disableTimeOut: true,
      positionClass: 'download-song-toastr',
    });
    this.authenticationService.login({ workspaces: [workspace] }, true).subscribe({
      next: (lRes) => {
        const userData = lRes?.data;
        if (userData?.redirect?.url && userData?.redirect?.token) {
          this.domainChange(userData);
        } else {
          this.toastr.clear(this.toastRef.toastId);
          this.toastr.warning(
            `Something happened and we couldn't redirect you to ${workspace}`,
            '',
          );
          this.trackingService.track('Workspace redirection  without redirect data', {
            workspace: workspace,
            userData: userData,
          });
        }
      },
      error: (err) => {
        this.toastr.clear(this.toastRef.toastId);
        this.toastr.error(`Something went wrong and we couldn't redirect you to ${workspace}`, '');
        this.trackingService.track(`Error trying to redirect to the ${workspace} Workspace`, {
          workspace: workspace,
          error: err,
        });
      },
    });
  }

  private domainChange(userData) {
    this.toastr.clear(this.toastRef.toastId);
    const urlNavigate = this.route.snapshot.queryParams.returnUrl
      ? decodeURIComponent(this.route.snapshot.queryParams.returnUrl)
      : '';
    const oldParams = urlNavigate.split('?');
    const url = `${userData.redirect.url}/auth/${userData.redirect.token}`;
    const queryParams = `${urlNavigate ? '?goTo=' + oldParams[0] : ''}${
      oldParams.length > 1 ? '&' + oldParams[1] : ''
    }`;
    window.location.href = `${url}${queryParams}`;
  }
}
